<!-- <p>home works!</p>
<button mat-raised-button (click)="gotoInstuction();"> goToInstuction Page<i class="fas fa-eye"
    aria-hidden="true"></i>
</button>
<button class="btn" onclick="logout()"><a>Logout</a></button> -->

<div class="container-fluid secondsection" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6 cont_sec">
                <h3 data-aos="fade-up">Welcome to <span> PBOX </span></h3>
                <p data-aos="fade-up">Taking an exam from home, with automated remote proctoring becomes new normal.
                    PBOX
                    (Proctoring Based Online eXamination) is designed with a view to accommodate most of the
                    features to conduct an exam with integrity, ensuring its credibility and authenticity.</p>


                <h4 data-aos="fade-up">Exam is fun & at your own comfort</h4>
                <p data-aos="fade-up">PBOX give you comfort to lunch exam with few clicks and candidates can attend
                    exam from any
                    corner of the India with minimum network connectivity. Proctoring is possible through this using
                    various policy framework.</p>
                <p data-aos="fade-up">PBOX (Proctoring Based Online eXamination) is designed to take care of fair
                    examination using the
                    emerging AI/ML technologies which matches the candidate&#39;s face with the registered photo.
                    Remote
                    proctoring module helps the proctor invigilate the policy violations defined for each test from
                    a
                    remote location.</p>
            </div>
            <div class="col-md-6">
                <div>
                    <img class="img" src="../../../assets/new-template/images/bg_laptop.jpg" alt="">
                </div>
                <div>
                    <button style="margin-left: 3.8rem; background-color: #33b60f !important;" class="btn"
                        (click)="gotoInstuction();"> Proceed To Verification
                    </button>
                    <button style="margin-left: 3.0rem;" class="btn btn1" data-toggle="modal"
                        data-target="#logout"><a>Logout</a></button>

                </div>
            </div>

        </div>

    </div>
</div>

<div class="modal fade" id="logout" tabindex="1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document1">
        <div class="modal-content">
            <div class="modal-header deleteheader bg-primary">
                <h2 class="modal-title" id="ModalLabel">Confirmation</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h4>Are you sure want to LogOut ?</h4>
            </div>
            <div class="modal-footer">
                <button class="btn" style="background-color: darkgrey !important" data-dismiss="modal">Close</button>
                &nbsp;
                <button class="btn btn-danger" data-dismiss="modal" (click)="logout();">Logout</button>
            </div>
        </div>
    </div>
</div>