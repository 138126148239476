
	<div class="container-fluid footer">
		<div class="container">
			<div class="row">
				<div class="col-md-3">
					<img src="assets/new-template/images/niclogo.png" style="width: 100%;"/>
				</div>
				<div class="col-md-6">
					<p>
						&copy; Content owned, updated and maintained by the NIC. PBOX platform is
						designed, developed and hosted by National Informatics Center, Ministry of 
						Electronics & Information Technology, Government of India.
					
					</p>
				</div>
				<div class="col-md-3">
					<h5>Follow us</h5>
					<ul>
						<li><i class="fab fa-facebook"></i></li>
						<li><i class="fab fa-twitter"></i></li>
					</ul>
				</div>
			
			</div>
		</div>
	</div>	