import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { serverUrl } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxIndexedDBService } from 'ngx-indexed-db';
declare const aware: any;


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private dbService: NgxIndexedDBService,
  ) { }
  clearQuestionAnswerFromLocalDb() {
    this.dbService.clear('questions').subscribe((successDeleted) => {
    });
    this.dbService.clear('answers').subscribe((successDeleted) => {
    });
    aware.clearDb();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(resolve => {
      if (this.authService.isExistToken()) {
        // let token = {
        //   eNo: this.authService.getENo(),
        //   examCode: this.authService.getExamCode(),
        //   subCode: this.authService.getSubCode(),
        //   slotId: this.authService.getSlotId(),
        //   token: this.authService.getToken()
        // }
        var token = {
          token: this.authService.getToken()
        }
        this.authService.authenticateUser(token).subscribe((result: any) => {
          if (result.status === "active") {
            this.authService.eNo = result.eNo;
            this.authService.examCode = result.examCode;
            this.authService.subCode = result.subCode;
            this.authService.slotId = result.slotId;
            this.authService.name = result.name;
            this.authService.examName = result.examName;
            this.authService.isPWD = result.isPWD;

            resolve(true);
          } else if (result.status === "inActive") {
            this.toastr.error(result.reason);
            setTimeout(() => {
              this.clearQuestionAnswerFromLocalDb();
              this.authService.logout();
              window.location.replace(`${serverUrl}/home.html`);
            }, 5000)
            resolve(false);
          } else if (result.status === "invalidToken") {
            this.toastr.error('Login from different device');
            setTimeout(() => {
              this.clearQuestionAnswerFromLocalDb();
              this.authService.logout();
              window.location.replace(`${serverUrl}/home.html`);
            }, 5000)
            resolve(false);
          }
          //  else if (result.status === "netWorkError") {
          //   console.log("netWorkError");

          //   this.toastr.error('Network Issue2 ');
          //   setTimeout(() => {
          //     this.clearQuestionAnswerFromLocalDb();
          //     this.authService.logout();
          //     window.location.replace(`${serverUrl}/home.html`);
          //   }, 5000)
          //   resolve(false);
          // } 
          else {
            this.toastr.error('Network Problem');
            setTimeout(() => {
              this.clearQuestionAnswerFromLocalDb();
              this.authService.logout();
              window.location.replace(`${serverUrl}/home.html`);
            }, 5000)
            resolve(false);
          }
          // window.location.replace(`${serverUrl}/home.html`);
        }, error => {
          window.location.replace(`${serverUrl}/home.html`);
        }
        );
      } else {
        this.clearQuestionAnswerFromLocalDb();
        this.authService.logout();
        window.location.replace(`${serverUrl}/home.html`);
        resolve(false);
      }
    });
  }

}
