import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serverUrl } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
declare const aware: any;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  eNo:string;
  examCode:string;
  subCode:string;
  slotId:string;
  name:string;
  examName:string;
  subName:string;
  isPWD:any;
  constructor(
    private http: HttpClient,
    private cookieeService: CookieService
  ) { }

  authenticateUser(token: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/authenticateUser`, token);
  }
  getAdminServerDomain(token: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/authenticateUser`, token);
  }
  authenticateExamStartTime(token: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/authenticateExamStartTime`, token);
  }

  getENo(): string {
    return this.cookieeService.get('eNo');
  }
  getExamCode(): string {
    return this.cookieeService.get('examCode');
  }
  getSubCode(): string {
    return this.cookieeService.get('subCode');
  }
  getUserName(): string {
    return this.cookieeService.get('name');
  }
  getExamName(): string {
    return this.cookieeService.get('examName');
  }
  getSubName(): string {
    return this.cookieeService.get('subName');
  }
  getToken(): string {
    return this.cookieeService.get('token');
  }
  getSlotId(): string {
    return this.cookieeService.get('slotId');
  }
  isExistToken(): boolean {
    return !!this.cookieeService.get('token');
  }
  logout(): boolean {
    // aware.deletedatabase1();
    this.cookieeService.deleteAll();
    return true
  }
  getModelType(): string {
      return this.cookieeService.get('modelType');
  }
}
