import { ExamService } from './../../services/exam.service';
import { AuthService } from '../../services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Component, OnInit } from '@angular/core';
import { interval, timer } from 'rxjs';
import { serverUrl } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MatTableDataSource } from '@angular/material/table';
declare const aware: any;
declare const window: any;

@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.css']
})
export class QuestionAnswerComponent implements OnInit {
  time: number = 0;
  display: any;
  interval: any;
  examCode: string;
  subCode: string;
  eNo: string;
  slotId: string;
  userName: string;
  examName: string;
  subName: string;
  fromDateTime: any;
  toDateTime: any;
  duration: any;
  currentTime: any;
  updateTimeSubscription: any;
  isSyncStarted: boolean;
  waitingMessage: string;
  realTimeSubscription: any;
  oddEvenTimeSubscription: any;
  lnmTimeSubscription: any
  questionShowing: any;
  answerDbTableName: string;
  lastNo: any;
  sectionList: any;
  isIndexedDBMode: boolean;
  answersList: any;
  timerSubscription: any;
  hour: any;
  minute: any;
  second: any;
  syncAnswerType: any;
  alredyGivenAnswer: any;
  questionDta: any;
  studentInfo: any;
  examInfo: any;
  setDet: any;
  //descriptiveAnswer: any;
  quillConfiguration = QuillConfiguration
  summaryData: any;
  questionSummary: any;
  answerSummary: any;
  totalAnswered: any;
  totalAnsweredMarked: any;
  // notVisited
  summaryFooter: boolean;
  submitFooter: boolean;
  questionListSummary: any;
  summaryTable: boolean = false;
  lastSection: any;
  isSmartlockEnable: any;
  isNetworkIssue: boolean = false;
  constructor(
    private service: ExamService,
    private dbService: NgxIndexedDBService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private authService: AuthService,
    private cookieeService: CookieService
  ) {
    // this.getCsrfToken();
    this.userName = this.authService.getUserName();
    this.examName = this.authService.getExamName();
    this.subName = this.authService.getSubName();
    this.examCode = this.authService.getExamCode();
    this.subCode = this.authService.getSubCode();
    this.eNo = this.authService.getENo();
    this.slotId = this.authService.getSlotId();
    this.examInfo = {};
    this.summaryData = new MatTableDataSource();
  }
  result: Array<any>
  x: number = 0;
  y: number = 1;
  value: any;
  ngOnInit(): void {
    // this.clearQuestionAnswerFromLocalDb();
    this.service.getExamDetail(this.examCode, this.subCode).subscribe(examResult => {
      this.isSmartlockEnable = examResult.isSmartlockEnable;
    })
    // window.addEventListener("keydown", (ev) => {
    //   if ((ev.key === "r" || ev.key === "R") && (ev.ctrlKey || ev.metaKey)) {
    //     ev.preventDefault();
    //   }
    //   if ((ev.key === "n" || ev.key === "N") && ev.ctrlKey) {
    //     ev.preventDefault();
    //   }
    //   if (ev.ctrlKey && ev.shiftKey && ev.keyCode == 73) {
    //     ev.preventDefault();  //Prevent from ctrl+shift+i
    //   }
    // });
    this.startTime(0, 0);
    this.answerDbTableName = 'answers';
    // document.addEventListener('fullscreenchange', (event) => {
    //   if (!document.fullscreenElement) {
    //     const fseVioObj = { status: 'FSE', isSynced: 'No' }
    //     aware.addVioToLocalDb(fseVioObj);
    //     let token = {
    //       eNo: this.authService.getENo(),
    //       examCode: this.authService.getExamCode(),
    //       subCode: this.authService.getSubCode(),
    //       slotId: this.authService.getSlotId(),
    //       token: this.authService.getToken()
    //     }
    //     this.authService.authenticateUser(token).subscribe((result: any) => {
    //       if (result) {
    //         this.toastr.error('By exiting from full screen mode you have violated the conduct policy of exam. So you are forcefull logged out', 'Violation detect')
    //         let reasonDetails = {
    //           eNo: this.authService.getENo(),
    //           examCode: this.authService.getExamCode(),
    //           subCode: this.authService.getSubCode(),
    //           slotId: this.authService.getSlotId(),
    //           status: "fullscreenchange",
    //           reason: "Forced logout due to full screen exit",
    //         }
    //         this.service.getAuthenticateUser(reasonDetails).subscribe(results => {
    //           this.submit();
    //         }, error => { this.toastr.error('Network problem.'); });
    //       } else {
    //         this.clearQuestionAnswerFromLocalDb();
    //         this.toastr.error('Login from different device');
    //         setTimeout(() => {
    //           this.authService.logout();
    //           window.logout();
    //           window.location.replace(`${serverUrl}/studentLogin.html`);
    //         }, 5000)
    //       }
    //     });
    //   }
    // });
    this.ngxLoader.start();
    this.lastNo = +this.eNo[this.eNo.length - 1];
    this.questionShowing = 0;
    this.lastSection = 0;
    this.result = [];
    this.currentTime = new Date();
    this.updateCurrentTime();
    this.waitingMessage = 'Load questions..';
    this.isSyncStarted = false;
    this.sectionList = [];
    this.answersList = [];
    this.isIndexedDBMode = true;
    this.loadQuestionsByMode();
    // this.loadStudentInfo(this.eNo);
    // ==========================================================================
    // let stdImg: any;
    // if (stdImg == '' || stdImg == undefined || stdImg == null) {
    //   stdImg = `${serverUrl}/studentPics/default.jpg`;
    //   this.studentInfo = stdImg;
    // } else {
    //   stdImg = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;
    //   this.studentInfo = stdImg;
    // }

    this.studentInfo = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;


    // ==========================================================================
    // this.studentInfo = `${serverUrl}/studentPics/${this.eNo}.jpg`;
    // this.studentInfo = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;
    this.hour = 0;
    this.minute = 0;
    this.second = 0;
    // getExamTime1,this.eNo
    this.service.getExamTime1(this.examCode, this.subCode, this.slotId, this.eNo).subscribe(result => {
      this.startTimer(result.duration);
      this.syncAnswerType = result.answerSyncType;
      this.examInfo = result.subName;
    }, error => this.toastr.error(error.statusText, error.status));
    // this.service.getAnswerSyncMode(this.examCode, this.subCode).subscribe(result => {
    //   this.syncAnswerType = result.answerSyncType;
    // })
    // this.getExamDetail(this.examCode, this.subCode);
  }
  // Get csrf token and add to the browser cookie
  // getCsrfToken() {
  //   this.service.getCsrfToken().subscribe(result => {
  //   });
  // }
  startTime(index: any, qindex: any) {
    this.interval = setInterval(() => {
      this.sectionList[index].questionList[qindex].timeSpend++;
      this.display = this.transform(this.sectionList[index].questionList[qindex].timeSpend);
    }, 1000);
  }
  pauseTimer() {
    clearInterval(this.interval);
  }
  transform(value: number): string {
    var sec_num = value;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    return this.questionViewTime(hours, minutes, seconds);
  }
  questionViewTime(hours: string | number, minutes: string | number, seconds: string | number) {

    switch (true) {
      case hours < 10 && minutes < 10 && seconds < 10:
        return '0' + hours + ' : 0' + minutes + ' : 0' + seconds;
        break;
      case hours < 10 && minutes < 10 && seconds > 9:
        return '0' + hours + ' : 0' + minutes + ' :' + seconds;
        break;
      case hours < 10 && minutes > 9 && seconds < 10:
        return '0' + hours + ' : ' + minutes + ' : 0' + seconds;
        break;
      case hours < 10 && minutes > 9 && seconds > 9:
        return '0' + hours + ' : ' + minutes + ' : ' + seconds;
        break;
      case hours > 9 && minutes < 10 && seconds < 10:
        return hours + ' : 0' + minutes + ' : 0' + seconds;
        break;
      case hours > 9 && minutes < 10 && seconds > 9:
        return hours + ' : 0' + minutes + ' : ' + seconds;
        break;
      case hours > 9 && minutes > 9 && seconds > 9:
        return hours + ' : ' + minutes + ' : ' + seconds;
        break;
    }
  }
  // getExamDetail(examCode: string, subCode: string) {
  //   this.service.getExamDetail(examCode, subCode).subscribe(result => {
  //     this.examInfo = result;
  //   });

  // }
  //this is implemented in line no 156
  // loadStudentInfo(eNo: string) {
  //   this.service.getStudentInfo(eNo).subscribe(async result => {
  //     this.studentInfo = result.imageUrl;
  //     this.studentInfo = `${serverUrl}/student-pics/${eNo}.jpg`;
  //   });
  // }

  loadQuestionsByMode() {
    // console.log(this.isIndexedDBMode,"this.isIndexedDBMode");
    if (this.isIndexedDBMode) {
      this.checkQuestionIsAvailOrNot();
    } else {
      // console.log("loadQuestionsInArray calleed");
      this.loadQuestionsInArray();
    }
  }
  loadQuestionsInArray() {
    let setNo = this.cookieeService.get(`${this.examCode}${this.subCode}${this.eNo}SetNo`);
    if (setNo == "undefined" || setNo == '') {
      setNo = '0';
    }
    this.service.getQuestion(this.examCode, this.subCode, this.slotId, this.eNo, setNo).subscribe(questions => {
      this.sectionList = questions.sectionList;
      if (questions.isUploaded) {
        questions.examCode = this.examCode;
        questions.subCode = this.subCode;
        questions.eNo = this.eNo;
        // this.setDet = this.result.map((e) => { return e.setNo })
        // this.setDet = this.setDet[0];
        this.result = JSON.parse(questions.questions);
        this.result = this.result.map((e, index) => { e.optionID = ''; e.slNo = index + 1; return e; });
        this.ngxLoader.stop();
        this.waitingMessage = '';
        this.startSyncBySyncMode();
        this.loadHtmlTemplateDependencies();
        if (setNo == "0") {
          this.cookieeService.set(`${this.examCode}${this.subCode}${this.eNo}SetNo`, questions.newSetNo);
        }
      } else {
        this.ngxLoader.stop();
        this.toastr.error('Questions are not uploaded. Please refresh after few moment.');
        this.waitingMessage = 'Questions not uploaded. Try after some moment.';
      }
    });
  }
  getAnswersFromCookieToArray() {
    const cookieAnswer = this.cookieeService.get(`${this.examCode}${this.subCode}${this.eNo}Answers`)
    let answers: any = [];
    if (cookieAnswer == "undefined" || cookieAnswer == '') {
      answers = []
    } else {
      answers = JSON.parse(cookieAnswer);
    }
    this.answersList = answers;
    for (let i = 0; i < this.result.length; i++) {
      for (let j = 0; j < answers.length; j++) {
        if (this.result[i].questionID === answers[j].questionID) {
          this.result[i].optionID = this.result[i].mode === 'Single' ? answers[j].optionID[0] : answers[j].optionID;
          let value = document.getElementById(`questionId${this.result[i].questionID}`);
          value.classList.remove("synced-answer");
          value.classList.remove("marked");
          value.classList.add('answered');
        }
      }
    }
    this.waitingMessage = '';
  }
  checkQuestionIsAvailOrNot() {
    this.dbService.openCursorByIndex('questions', 'Value', IDBKeyRange.only([this.examCode, this.subCode, this.eNo])).subscribe((evt) => {
      var cursor: any = (evt.target as IDBOpenDBRequest).result;
      if (!cursor) {
        this.getQuestionsFromServer();
        this.recordExamStart();
      } else {
        const result = cursor.value;
        this.getAnswersFromLocalDb();
        this.loadQestions();
      }
    });
  }
  getQuestionsFromServer() {
    // this.result = [];
    let setNo;
    this.service.getQuestion(this.examCode, this.subCode, this.slotId, this.eNo, setNo).subscribe(questions => {
      // if (questions) {
      this.result = JSON.parse(questions.questions)
      this.alredyGivenAnswer = questions.answerResults;//Already given Answer list
      this.sectionList = questions.sectionList;
      if (questions.isUploaded) {
        questions.examCode = this.examCode;
        questions.subCode = this.subCode;
        questions.eNo = this.eNo;
        // this.setDet = this.result.map((e) => { return e.setNo })
        // this.setDet = this.setDet[0];
        this.dbService.add('questions', questions).subscribe((key) => {
          this.loadQestions();
          this.waitingMessage = '';
        });
      } else {
        this.ngxLoader.stop();
        this.toastr.error('Questions are not uploaded. Please refresh after few moment.');
        this.waitingMessage = 'Questions not uploaded. Try after some moment.';
      }
      if (this.alredyGivenAnswer) {
        setTimeout(() => {
          // if (i == this.result.length - 1) {
          for (let i = 0; i < this.result.length; i++) {
            for (let k = 0; k < this.alredyGivenAnswer.length; k++) {
              if (this.result[i].questionID === this.alredyGivenAnswer[k].questionID) {
                this.alredyGivenAnswer[k].isSynced = "Yes";
                this.result[i].optionID = this.result[i].mode === 'Single' ? JSON.stringify(this.alredyGivenAnswer[k].optionID[0]) : JSON.stringify(this.alredyGivenAnswer[k].optionID);
                if (this.result[i].mode === 'Multiple') {
                  for (let j = 0; j < this.result[i].options.length; j++) {
                    for (let l = 0; l < this.alredyGivenAnswer[k].optionID.length; l++) {
                      if (this.result[i].options[j].id === this.alredyGivenAnswer[k].optionID[l]) {
                        this.result[i].optionID = JSON.stringify(this.alredyGivenAnswer[k].optionID[l]);
                        this.result[i].options[j].checked = true;
                      }
                    }
                  }
                }
                this.dbService.add(this.answerDbTableName, this.alredyGivenAnswer[k]).subscribe((storeData) => {
                  if (storeData) {
                    this.ngxLoader.stop();
                    let value = document.getElementById(`questionId${this.alredyGivenAnswer[k].questionID}`);
                    if (this.alredyGivenAnswer[k].ismarked == true) {
                      value.classList.add('marked');
                      value.classList.remove('answered');
                      value.classList.remove('synced-answer');
                    } else {
                      value.classList.remove('marked');
                      value.classList.remove('answered');
                      value.classList.add('synced-answer');
                    }
                  }
                });
                // }
              }
            }
          }
        }, 1000);
      }
      // }
    });
  }
  // Assign Value (ismarked) for Review Questions
  assignIsmarkedValueForReviewAndConfirm() {
    if (this.alredyGivenAnswer != undefined) {
      for (let k = 0; k < this.alredyGivenAnswer.length; k++) {
        for (let i = 0; i < this.sectionList.length; i++) {
          for (let j = 0; j < this.sectionList[i].questionList.length; j++) {
            if (this.sectionList[i].questionList[j].questionID == this.alredyGivenAnswer[k].questionID) {
              this.sectionList[i].questionList[j].ismarked = this.alredyGivenAnswer[k].ismarked;
            }
          }
        }
      }
    }
  }
  getAnswersFromLocalDb() {
    this.dbService.getAll(this.answerDbTableName).subscribe((answers) => {
      this.alredyGivenAnswer = answers;
      if (this.alredyGivenAnswer) {
        setTimeout(() => {
          for (let i = 0; i < this.result.length; i++) {
            for (let j = 0; j < this.alredyGivenAnswer.length; j++) {
              if (this.result[i].questionID === this.alredyGivenAnswer[j].questionID) {
                this.result[i].optionID = this.result[i].mode === 'Single' ? this.alredyGivenAnswer[j].optionID[0] : this.alredyGivenAnswer[j].optionID;
                if (this.result[i].mode === 'Multiple') {
                  for (let k = 0; k < this.result[i].options.length; k++) {
                    for (let l = 0; l < this.alredyGivenAnswer[j].optionID.length; l++) {
                      if (this.result[i].options[k].id === this.alredyGivenAnswer[j].optionID[l]) {
                        this.result[i].optionID = this.alredyGivenAnswer[j].optionID[l]
                        this.result[i].options[k].checked = true;
                      }
                    }
                  }
                }
                else if (this.result[i].mode === 'Descriptive') {
                  this.result[i].descriptiveAnswer = this.alredyGivenAnswer[j].descriptiveAnswer
                }
                let value = document.getElementById(`questionId${this.result[i].questionID}`);
                if (this.alredyGivenAnswer[j].ismarked == true) {
                  value.classList.add('marked');
                  value.classList.remove('answered');
                  value.classList.remove('synced-answer');
                } else {
                  if (this.alredyGivenAnswer[j].isSynced === 'Yes') {
                    value.classList.remove("marked");
                    value.classList.remove('answered');
                    value.classList.add("synced-answer");
                  } else {
                    value.classList.remove("synced-answer");
                    value.classList.remove("marked");
                    value.classList.add('answered');
                  }
                }
                break;
              }
            }
          }
        }, 2000);
      }
      this.waitingMessage = '';
      this.ngxLoader.stop();
    });
  }
  loadQestions() {
    this.dbService.openCursorByIndex('questions', 'Value', IDBKeyRange.only([this.examCode, this.subCode, this.eNo])).subscribe((evt) => {
      var cursor: any = (evt.target as IDBOpenDBRequest).result;
      const result = cursor.value;
      this.result = JSON.parse(result.questions);
      this.sectionList = result.sectionList;
      this.sectionList.forEach((e: any) => {
        e.questionList = this.result.filter(que => e.sectionCode === que.sectionCode)
        e.x = 0;
        e.y = 1;
      })
      this.result = this.result.map((e, index) => { e.optionID = ''; e.slNo = index + 1; return e; });
      this.ngxLoader.stop();
      this.startSyncBySyncMode();
      this.loadHtmlTemplateDependencies();
      this.assignIsmarkedValueForReviewAndConfirm();
    });
  }

  startSyncBySyncMode() {
    switch (this.syncAnswerType) {
      case "realTime": {
        this.realTimeSync();
        break;
      }
      case "oddEven": {
        this.oddEvenTimeSync(this.lastNo);
        break;
      }
      case "lnm": {
        this.lnmTimeSync(this.lastNo);
        break;
      }
    }
  }

  realTimeSync() {
    const secondsCounter = interval(1000);
    this.realTimeSubscription = secondsCounter.subscribe(n => {
      this.syncToServer();
    });
  }
  oddEvenTimeSync(lastNo: any) {
    const secondsCounter = interval(1000);
    this.oddEvenTimeSubscription = secondsCounter.subscribe(n => {
      let currentSec = new Date(this.currentTime).getSeconds();
      if (lastNo % 2 === 0) {
        if (currentSec % 2 === 0) {
          this.syncToServer();
        }
      } else {
        if (currentSec % 2 !== 0) {
          this.syncToServer();
        }
      }
    });
  }
  lnmTimeSync(lastNo: any) {
    const secondsCounter = interval(1000);
    this.lnmTimeSubscription = secondsCounter.subscribe(n => {
      let currentSec = new Date(this.currentTime).getSeconds();
      if ((currentSec % 10) === lastNo) {
        this.syncToServer();
      }
    });
  }
  // FIXME: UPDATE HOUR
  updateCurrentTime() {
    this.updateTimeSubscription = interval(1000).subscribe(n => {
      let cSec = new Date(this.currentTime).getSeconds();
      let cMunite = new Date(this.currentTime).getMinutes();
      let setSeconds;
      if (cSec === 59) {
        let setMunite = new Date(this.currentTime).setMinutes(cMunite + 1);
        setSeconds = new Date(setMunite).setSeconds(0);
      } else {
        setSeconds = new Date(this.currentTime).setSeconds(cSec + 1)
      }
      this.currentTime = new Date(setSeconds);
    })
  }
  syncMultiAnswer(user: any, timeSpend: any) {
    if (this.isIndexedDBMode) {
      this.syncMultiAnswerToIndexedDb(user, timeSpend);
    } else {
      this.syncMultiAnswerToCookie(user, timeSpend);
    }
  }

  syncAnswer(user: any, timeSpend: any) {
    if (this.isIndexedDBMode) {
      this.syncSingleAnswerToIndexedDb(user, timeSpend);
    } else {
      this.syncSingleAnswerToCookiee(user, timeSpend);
    }
  }

  saveDescriptive(user: any, timeSpend: any) {
    // if (this.authService.isExistToken()) {
    //   let token = {
    //     eNo: this.authService.getENo(),
    //     examCode: this.authService.getExamCode(),
    //     subCode: this.authService.getSubCode(),
    //     slotId: this.authService.getSlotId(),
    //     token: this.authService.getToken()
    //   }

    //   this.authService.authenticateUser(token).subscribe((result: any) => {
    //     if (result === true) {
    if (this.isIndexedDBMode) {
      this.syncSingleAnswerToIndexedDb(user, timeSpend);
    } else {
      //Add insertData to cookies just like done in syncSingleAnswerToIndexedDb
      this.syncSingleAnswerToCookiee(user, timeSpend);
    }
    // }
    //  else {
    //   this.toastr.error('Login from different device');

    //   setTimeout(() => {
    //     this.clearQuestionAnswerFromLocalDb();
    //     this.authService.logout();
    //     window.location.replace(`${serverUrl}/studentLogin.html`);
    //   }, 5000)
    // }
    //   }, error => {
    //     this.toastr.error('failed to execute');
    //     setTimeout(() => {
    //       this.authService.logout();
    //       window.location.replace(`${serverUrl}/studentLogin.html`);
    //     }, 5000)
    //   }
    //   );
    // } else {
    //   this.authService.logout();
    //   window.location.replace(`${serverUrl}/home.html`);
    // }
  }
  syncSingleAnswerToIndexedDb(user: any, timeSpend: any) {
    let insertData
    if (user.mode !== "Descriptive") {
      insertData = {
        examCode: this.examCode,
        subCode: this.subCode,
        eNo: this.eNo,
        slotId: this.slotId,
        setNo: user.setNo,
        questionNo: user.questionNo,
        questionID: user.questionID,
        optionID: [parseInt(user.optionID)],
        isSynced: "No",
        sectionName: user.sectionName,
        sectionCode: user.sectionCode,
        anserSyncTo: user.anserSyncTo,
        ismarked: user.ismarked,
        answerGivenTime: new Date().toISOString(),
        mode: user.mode,
        timeSpend: timeSpend
      }
    }
    else if (user.mode === "Descriptive") {
      if (user.descriptiveAnswer == "" || user.descriptiveAnswer == null || user.descriptiveAnswer == undefined) {
        this.toastr.warning('Please write your answer to save it')
      } else {
        insertData = {
          examCode: this.examCode,
          subCode: this.subCode,
          eNo: this.eNo,
          slotId: this.slotId,
          setNo: user.setNo,
          questionNo: user.questionNo,
          questionID: user.questionID,
          optionID: ["Descriptive"],
          isSynced: "No",
          ismarked: user.ismarked,
          sectionName: user.sectionName,
          sectionCode: user.sectionCode,
          anserSyncTo: user.anserSyncTo,
          descriptiveAnswer: user.descriptiveAnswer,
          answerGivenTime: new Date().toISOString(),
          mode: user.mode,
          timeSpend: timeSpend
        }
      }
    }
    if (user.ismarked == "NA") {
      insertData.ismarked = false
    }
    // let value = document.getElementById(`questionId${insertData.questionID}`);
    this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
      let value = document.getElementById(`questionId${insertData.questionID}`);
      if (insertData.ismarked == true) {
        value.classList.remove('answered');
        value.classList.remove('synced-answer');
        value.classList.add('marked');
      } else {
        value.classList.remove('marked');
        value.classList.remove('synced-answer');
        value.classList.add('answered');
      }
    });
  }
  syncSingleAnswerToCookiee(user: any, timeSpend: any) {
    let insertData
    if (user.mode !== "Descriptive") {
      insertData = {
        examCode: this.examCode,
        subCode: this.subCode,
        eNo: this.eNo,
        slotId: this.slotId,
        setNo: user.setNo,
        questionNo: user.questionNo,
        questionID: user.questionID,
        optionID: [parseInt(user.optionID)],
        isSynced: "No",
        sectionName: user.sectionName,
        sectionCode: user.sectionCode,
        anserSyncTo: user.anserSyncTo,
        answerGivenTime: new Date().toISOString(),
        mode: user.mode,
        ismarked: user.ismarked,
        timeSpend: timeSpend
      }
    }
    else if (user.mode === "Descriptive") {
      if (user.descriptiveAnswer == "" || user.descriptiveAnswer == null || user.descriptiveAnswer == undefined) {
        this.toastr.warning('Please write your answer to save it')
      } else {
        insertData = {
          examCode: this.examCode,
          subCode: this.subCode,
          eNo: this.eNo,
          slotId: this.slotId,
          setNo: user.setNo,
          questionNo: user.questionNo,
          questionID: user.questionID,
          optionID: ["Descriptive"],
          isSynced: "No",
          sectionName: user.sectionName,
          sectionCode: user.sectionCode,
          anserSyncTo: user.anserSyncTo,
          ismarked: user.ismarked,
          descriptiveAnswer: user.descriptiveAnswer,
          answerGivenTime: new Date().toISOString(),
          mode: user.mode,
          timeSpend: timeSpend
        }
      }
    }
    if (user.ismarked == "NA") {
      insertData.ismarked = false
    }
    const isAvail = this.answersList.some((e: any) => e.questionID === insertData.questionID);
    if (!isAvail) {
      this.answersList.push(insertData);
    } else {
      const index = this.answersList.findIndex((e: any) => e.questionID === insertData.questionID);
      this.answersList[index] = insertData;
    }

    this.cookieeService.delete(`${this.examCode}${this.subCode}${this.eNo}Answers`);
    this.cookieeService.set(`${this.examCode}${this.subCode}${this.eNo}Answers`, JSON.stringify(this.answersList));
    let value = document.getElementById(`questionId${insertData.questionID}`);
    if (insertData.ismarked == true) {
      value.classList.remove('answered');
      value.classList.remove('synced-answer');
      value.classList.add('marked');
    } else {
      value.classList.remove('marked');
      value.classList.remove('synced-answer');
      value.classList.add('answered');
    }
  }
  syncMultiAnswerToIndexedDb(user: any, timeSpend: any) {
    let insertData = {
      examCode: this.examCode,
      subCode: this.subCode,
      eNo: this.eNo,
      slotId: this.slotId,
      setNo: user.setNo,
      questionNo: user.questionNo,
      questionID: user.questionID,
      optionID: [],
      isSynced: "No",
      sectionName: user.sectionName,
      sectionCode: user.sectionCode,
      anserSyncTo: user.anserSyncTo,
      ismarked: user.ismarked,
      answerGivenTime: new Date().toISOString(),
      mode: user.mode,
      timeSpend: timeSpend
    }
    if (user.ismarked == "NA") {
      insertData.ismarked = false
    }
    let options = user.options;
    options.forEach(function (k: any) {
      if (k.checked == true) {
        insertData.optionID.push(parseInt(k.id))
      }
    });
    if (insertData.optionID == undefined || insertData.optionID.length == 0) {
      //   for (let i = 0; i < this.sectionList.length; i++) {
      //     for (let j = 0; j < this.sectionList[i].questionList.length; j++) {
      //       if (this.sectionList[i].questionList[j].questionID === user.questionID) {
      //         this.sectionList[i].questionList[j].ismarked = false;

      this.resetAnswer(user);
      // this.resetUpdate(insertData);
      //         this.dbService.delete(this.answerDbTableName, user.questionID).subscribe((storeData) => {
      //           let value = document.getElementById(`questionId${user.questionID}`);
      //           value.classList.remove("synced-answer");
      //           value.classList.remove("marked");
      //           value.classList.remove('answered');
      //         })
      //       }
      //     }
      //   }
    } else {
      this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
        let value = document.getElementById(`questionId${insertData.questionID}`);
        value.classList.remove("synced-answer");
        value.classList.remove("marked");
        value.classList.add('answered');
      });
    }
  }
  // when uncheck all option data should be delete from database and indexdb Not Done
  syncMultiAnswerToCookie(user: any, timeSpend: any) {
    let insertData = {
      examCode: this.examCode,
      subCode: this.subCode,
      eNo: this.eNo,
      slotId: this.slotId,
      setNo: user.setNo,
      questionNo: user.questionNo,
      questionID: user.questionID,
      optionID: [],
      isSynced: "No",
      sectionName: user.sectionName,
      sectionCode: user.sectionCode,
      anserSyncTo: user.anserSyncTo,
      ismarked: user.ismarked,
      answerGivenTime: new Date().toISOString(),
      mode: user.mode,
      timeSpend: timeSpend
    }
    if (user.ismarked == "NA") {
      insertData.ismarked = false
    }
    let options = user.options;
    options.forEach(function (k: any) {
      if (k.checked == true) {
        insertData.optionID.push(parseInt(k.id))
      }
    });
    const isAvail = this.answersList.some((e: any) => e.questionID === insertData.questionID);
    if (!isAvail) {
      this.answersList.push(insertData);
    } else {
      const index = this.answersList.findIndex((e: any) => e.questionID === insertData.questionID);
      this.answersList[index] = insertData;
    }
    this.cookieeService.delete(`${this.examCode}${this.subCode}${this.eNo}Answers`);
    this.cookieeService.set(`${this.examCode}${this.subCode}${this.eNo}Answers`, JSON.stringify(this.answersList));
    let value = document.getElementById(`questionId${insertData.questionID}`);
    if (insertData.ismarked == true) {
      value.classList.remove('answered');
      value.classList.remove('synced-answer');
      value.classList.add('marked');
    } else {
      value.classList.remove('marked');
      value.classList.remove('synced-answer');
      value.classList.add('answered');
    }
  }
  next(index: any) {
    //(this.sectionList[index].x) in this case "X" is the current Index And "X+1" is the next index of the current index .  Ex :- x = 5 and y = 4 click in "Next" button x = 6 and y = 5 ;
    //(this.sectionList[index].y) in this case "Y" is the previous Index of "x or (current index)" And "y+1" is the next index of the "y".  Ex :- y = 4 and x = 5 click in "Next" button y = 5 and x = 6
    //x and y indexes are pointer to the next question of the current selected question.
    this.sectionList[index].x = this.sectionList[index].x + 1;//pointer to the next question from current Question.
    this.sectionList[index].y = this.sectionList[index].y + 1;//pointer to the current question from previous Question.
    if (this.sectionList[index].x >= this.sectionList[index].questionList.length) {
      if (this.sectionList[index + 1]) {
        this.sectionList[index + 1].x = 0;
        this.sectionList[index + 1].y = 1;
        document.getElementById(`collapse${index}`).classList.remove('show');
        const a = document.getElementById(`collapse${index + 1}`).classList.add('show');
        this.questionShowing = this.sectionList[index + 1].x;
        this.pauseTimer();
        this.startTime(index + 1, this.sectionList[index + 1].x);
      } else {
        this.sectionList[index].x = this.sectionList[index].x - 1;
        this.sectionList[index].y = this.sectionList[index].y - 1;
      }
    } else {
      this.questionShowing = this.sectionList[index].x;
      this.pauseTimer();
      this.startTime(index, this.sectionList[index].x);
    }

  }
  public prev(index: any): void {
    //(this.sectionList[index].x) in this case "X" is the current Index And "X-1" is the previous index of the current index .  Ex :- x = 5 and y = 6 click in "prev" button x = 4 and y = 5 ;
    //(this.sectionList[index].y) in this case "Y" is the previous Index of "x or (current index)" And "y+1" is the next index of the "y".  Ex :- y = 6 and x = 5 click in "Next" button y = 5 and x = 4
    //x and y indexes are pointer to the previous question of the current selected question.
    this.sectionList[index].x = this.sectionList[index].x - 1;//pointer to the previous question from current Question.
    this.sectionList[index].y = this.sectionList[index].y - 1;//pointer to the current question from (current+next) Question.

    if (this.sectionList[index].x < 0) {

      if (this.sectionList[index - 1]) {
        this.sectionList[index - 1].x = this.sectionList[index - 1].questionList.length - 1;
        this.sectionList[index - 1].y = this.sectionList[index - 1].questionList.length;
        document.getElementById(`collapse${index}`).classList.remove('show');
        document.getElementById(`collapse${index - 1}`).classList.add('show');
        this.questionShowing = this.sectionList[index - 1].x;
        this.pauseTimer();
        this.startTime(index - 1, this.questionShowing);
      } else {
        this.sectionList[index].x = this.sectionList[index].x + 1;
        this.sectionList[index].y = this.sectionList[index].y + 1;
      }

    } else {
      this.questionShowing = this.sectionList[index].x;
      this.pauseTimer();
      this.startTime(index, this.sectionList[index].x);
    }
  }
  changesInCollapse(index: any) {
    const a = document.getElementById(`collapse${index}`).classList;
    const b = "fade collapse show";
    if (a.value == b) {
      this.pauseTimer();
    } else {
      this.sectionList[index].x = 0;
      this.sectionList[index].y = 1;
      this.pauseTimer();
      this.startTime(index, this.sectionList[index].x);
    }
  }

  gotoQuestionNo(x: any, index: any, tableIndex?: any) {
    let i = this.sectionList.findIndex((e: any) => e.sectionCode === x)
    this.sectionList[i].x = index;
    this.sectionList[i].y = index + 1;
    this.questionShowing = this.sectionList[i].x;
    if (tableIndex >= 0) {
      if (tableIndex != this.lastSection) {
        document.getElementById(`collapse${this.lastSection}`).classList.remove('show');
        document.getElementById(`collapse${tableIndex}`).classList.add('show');
      }
    }
    this.lastSection = i;
    this.pauseTimer();
    this.startTime(i, this.questionShowing);
  }
  async syncToServer() {
    if (!this.isSyncStarted) {
      this.dbService.getByIndex(this.answerDbTableName, 'isSynced', 'No').subscribe((data) => {
        if (data) {
          this.isSyncStarted = true;
          delete data['isSynced'];
          this.service.addAnswer(data).subscribe(result => {
            this.isNetworkIssue = false;
            if (result.message === 'InvalidToken') {
              this.isSyncStarted = false;
              this.clearQuestionAnswerFromLocalDb();
              this.toastr.error('Login from different device');
              if (this.isSmartlockEnable === "Yes") {
                this.authService.logout();
                window.logout();
                window.location.replace(`${serverUrl}/home.html`);
              } else {
                this.authService.logout();
                window.location.replace(`${serverUrl}/home.html`);
              }
            }
            else if (result.message === 'NetworkIsuue') {
              this.syncToServer()
            }
            else {
              if (data.answerGivenTime === result.answerGivenTime) {
                if (result.resetStatus === 'Reset') {
                  this.isSyncStarted = false;
                  this.resetUpdate(data);
                  this.dbService.delete(this.answerDbTableName, data.questionID).subscribe(result => {
                  })
                } else {
                  data.isSynced = "Yes";
                  let value = document.getElementById(`questionId${data.questionID}`);
                  if (data.ismarked == true) {
                    value.classList.add('marked');
                    value.classList.remove('answered');
                    value.classList.remove('synced-answer');
                  } else {
                    value.classList.remove('marked');
                    value.classList.remove('answered');
                    value.classList.add('synced-answer');
                  }
                  this.dbService.update(this.answerDbTableName, data).subscribe((storeData) => {
                    this.isSyncStarted = false;
                  });
                }

              }
            }
          }, error => {
            this.isSyncStarted = false;
            this.isNetworkIssue = true;
            // this.toastr.error('Network Issue');
          });
        }
      });
    }
  }
  submit() {
    this.dbService.getAllByIndex(this.answerDbTableName, 'isSynced', IDBKeyRange.only('No')).subscribe((dataToSync) => {

      let examDetails = {
        examCode: this.examCode,
        subCode: this.subCode,
        eNo: this.eNo,
        slotId: this.slotId
      }
      if (dataToSync.length !== 0) {
        dataToSync.forEach(data => { delete data['isSynced']; });
        this.service.submitFinalAnswer({ dataToSync, examDetails }).subscribe(result => {
          if (result.message === 'InvalidToken') {
            this.isSyncStarted = false;
            this.toastr.error('Login from different device');
            this.clearQuestionAnswerFromLocalDb();
            if (this.isSmartlockEnable === "Yes") {
              this.authService.logout();
              window.logout();
              window.location.replace(`${serverUrl}/home.html`);
            } else {
              this.authService.logout();
              window.location.replace(`${serverUrl}/home.html`);
            }
          } else if (result.message === 'NetworkIsuue') {
            // this.submit()
            console.log("network Issue");
          } else {
            dataToSync.forEach(e => {
              let value = document.getElementById(`questionId${e.questionID}`);
              value.classList.remove("marked");
              value.classList.remove("answered");
              value.classList.add('synced-answer');
            })
            this.clearQuestionAnswerFromLocalDb();
            this.toastr.success('Thanks you. Your answers successfully submitted to authority.');
            let reasonDetails = {
              eNo: this.authService.getENo(),
              examCode: this.authService.getExamCode(),
              subCode: this.authService.getSubCode(),
              slotId: this.authService.getSlotId(),
              status: "submit",
              reason: "Final Submit"
            }
            this.service.getAuthenticateUser(reasonDetails).subscribe(results => {
              if (results) {
                if (this.isSmartlockEnable === "Yes") {
                  this.authService.logout();
                  window.logout();
                  window.location.replace(`${serverUrl}/home.html`);
                } else {
                  this.authService.logout();
                  window.location.replace(`${serverUrl}/home.html`);
                }
              }
            }, error => {
              console.log(error);
            });
          }
        }, error => { this.toastr.error('Network problem.'); });
      } else {
        this.service.submitFinalAnswer({ examDetails: examDetails, dataToSync: [] }).subscribe(result => {
          if (result.message === 'InvalidToken') {
            this.isSyncStarted = false;
            this.toastr.error('Login from different device');
            this.clearQuestionAnswerFromLocalDb();
            if (this.isSmartlockEnable === "Yes") {
              this.authService.logout();
              window.logout();
              window.location.replace(`${serverUrl}/home.html`);
            } else {
              this.authService.logout();
              window.location.replace(`${serverUrl}/home.html`);
            }
          }
          else if (result.message === 'NetworkIsuue') {
            // this.submit()
            console.log("network Issue");
          } else {
            this.clearQuestionAnswerFromLocalDb();
            this.toastr.success('Thanks you. Your answers are already successfully submitted to authority.');
            let reasonDetails = {
              eNo: this.authService.getENo(),
              examCode: this.authService.getExamCode(),
              subCode: this.authService.getSubCode(),
              slotId: this.authService.getSlotId(),
              status: "submit",
              reason: "Final Submit"
            }
            this.service.getAuthenticateUser(reasonDetails).subscribe(results => {
              if (results) {
                if (this.isSmartlockEnable === "Yes") {
                  this.authService.logout();
                  window.logout();
                  window.location.replace(`${serverUrl}/home.html`);
                } else {
                  this.authService.logout();
                  window.location.replace(`${serverUrl}/home.html`);
                }
              }
            }, error => {
              console.log(error);
              this.toastr.error('Network problem.');
            });
          }
        }, error => { this.toastr.error('Network problem.'); });
      }
    });
  }
  removeQuestionsFromIndexedDb() { }
  onRightClick(e: any) {
    const rightClickVioObj = { status: 'rightClick', isSynced: 'No' }
    aware.addVioToLocalDb(rightClickVioObj);
    this.toastr.warning('Right click not allowed.');
    return false;
  }
  ngOnDestroy() {
    this.submit();
    this.updateTimeSubscription.unsubscribe();
    if (this.realTimeSubscription) {
      this.realTimeSubscription.unsubscribe();
    }
    if (this.oddEvenTimeSubscription) {
      this.oddEvenTimeSubscription.unsubscribe();
    }
    if (this.lnmTimeSubscription) {
      this.lnmTimeSubscription.unsubscribe();
    }
  }
  clearQuestionAnswerFromLocalDb() {
    this.dbService.clear('questions').subscribe((successDeleted) => {
    });
    this.dbService.clear(this.answerDbTableName).subscribe((successDeleted) => {
    });
    aware.clearDb();
  }
  recordExamStart() {
    let examDetail = {
      examCode: this.examCode,
      subCode: this.subCode,
      eNo: this.eNo,
      slotId: this.slotId
    }
    this.service.updateExamStartTime(examDetail).subscribe(result => {
      if (result) {
      } else {
        this.recordExamStart();
      }
    }, error => {
      this.recordExamStart();
    })
  }
  loadHtmlTemplateDependencies() {
    setTimeout(() => {
      window.loadQuestionAnswerPage();
      window.loadHeaderComponent();
      document.getElementById(`collapse0`).classList.add('show');
      this.sectionList.forEach((e: any, index: any) => {
        document.getElementById(`collapseButton${index}`).setAttribute('data-target', `#collapse${index}`);
      }, 2000);
      this.getAnswersFromCookieToArray();
    })
  }

  startTimer(duration: any) {
    this.hour = duration.hour;
    this.minute = duration.minute;
    this.second = duration.second;
    this.timerSubscription = timer(0, 1000).subscribe(x => {
      if (this.second === 0) {
        if (this.minute === 0) {
          if (this.second === 0 && this.minute === 0 && this.hour === 0) {
            this.timerSubscription.unsubscribe();
          } else {
            this.hour = this.hour - 1;
            this.minute = 59;
          }
        }
        if (this.second === 0 && this.minute === 0 && this.hour === 0) {
          this.timerSubscription.unsubscribe();
          this.submit();
          let reasonDetails = {
            eNo: this.authService.getENo(),
            examCode: this.authService.getExamCode(),
            subCode: this.authService.getSubCode(),
            slotId: this.authService.getSlotId(),
            reason: "Time over"
          }
          this.service.getAuthenticateUser(reasonDetails).subscribe(results => {
          }, error => { this.toastr.error('Network problem.'); });
          this.toastr.success('Thank you. Your exam time is over.', 'TIME OVER', {
            timeOut: 20000,
            positionClass: "toast-center-center",
            extendedTimeOut: 3000,
            tapToDismiss: false
          })
            .onHidden
            .subscribe(() => {
              if (this.isSmartlockEnable === "Yes") {
                this.authService.logout();
                window.logout();
                window.location.replace(`${serverUrl}/home.html`);
              } else {
                this.authService.logout();
                window.location.replace(`${serverUrl}/home.html`);
              }
            });
        } else {
          this.minute = this.minute - 1;
          this.second = 60;
        }
      } else {
        this.second = this.second - 1;
      }
    });
  }
  resetAnswer(user: any) {
    if (user.mode === "Single") {
      let insertData = {
        examCode: this.examCode,
        subCode: this.subCode,
        eNo: this.eNo,
        slotId: this.slotId,
        questionNo: user.questionNo,
        questionID: user.questionID,
        optionID: [parseInt(user.optionID)],
        isSynced: "No",
        sectionName: user.sectionName,
        sectionCode: user.sectionCode,
        anserSyncTo: user.anserSyncTo,
        ismarked: user.ismarked,
        answerGivenTime: new Date().toISOString(),
        mode: user.mode,
        setNo: user.setNo,
        resetStatus: "Reset",
      }
      if (user.ismarked == "NA") {
        insertData.ismarked = false
      }
      this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
        let value = document.getElementById(`questionId${insertData.questionID}`);
        value.classList.remove('marked');
        value.classList.remove('synced-answer');
        value.classList.add('answered');
      });
    } else if (user.mode === "Multiple") {
      let insertData = {
        examCode: this.examCode,
        subCode: this.subCode,
        eNo: this.eNo,
        slotId: this.slotId,
        questionNo: user.questionNo,
        questionID: user.questionID,
        optionID: [],
        isSynced: "No",
        sectionName: user.sectionName,
        sectionCode: user.sectionCode,
        anserSyncTo: user.anserSyncTo,
        ismarked: user.ismarked,
        answerGivenTime: new Date().toISOString(),
        mode: user.mode,
        setNo: user.setNo,
        resetStatus: "Reset",
      }
      if (user.ismarked == "NA") {
        insertData.ismarked = false
      }
      let options = user.options;
      options.forEach(function (k: any) {
        if (k.checked == true) {
          insertData.optionID.push(parseInt(k.id))
        }
      });
      this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
        let value = document.getElementById(`questionId${insertData.questionID}`);
        value.classList.remove("synced-answer");
        value.classList.remove("marked");
        value.classList.add('answered');
      });
    } else if (user.mode === "Descriptive") {
      let insertData = {
        examCode: this.examCode,
        subCode: this.subCode,
        eNo: this.eNo,
        slotId: this.slotId,
        questionNo: user.questionNo,
        questionID: user.questionID,
        isSynced: "No",
        sectionName: user.sectionName,
        sectionCode: user.sectionCode,
        anserSyncTo: user.anserSyncTo,
        ismarked: user.ismarked,
        answerGivenTime: new Date().toISOString(),
        mode: user.mode,
        setNo: user.setNo,
        resetStatus: "Reset",
      }
      if (user.ismarked == "NA") {
        insertData.ismarked = false
      }
      this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
        let value = document.getElementById(`questionId${insertData.questionID}`);
        value.classList.remove('marked');
        value.classList.remove('synced-answer');
        value.classList.add('answered');
      });
    }
  }

  resetUpdate(data: any) {
    for (let i = 0; i < this.sectionList.length; i++) {
      for (let j = 0; j < this.sectionList[i].questionList.length; j++) {
        if (this.sectionList[i].questionList[j].questionID === data.questionID) {
          this.sectionList[i].questionList[j].ismarked = false;
          if (this.sectionList[i].questionList[j].mode == "Single") {
            this.sectionList[i].questionList[j].optionID = [];
            let value = document.getElementById(`questionId${data.questionID}`);
            value.classList.remove("synced-answer");
            value.classList.remove("marked");
            value.classList.remove('answered');
          }
          if (this.sectionList[i].questionList[j].mode == "Multiple") {
            for (let k = 0; k < this.sectionList[i].questionList[j].options.length; k++) {
              if (this.sectionList[i].questionList[j].options[k].checked == true) {
                this.sectionList[i].questionList[j].options[k].checked = false;
                let value = document.getElementById(`questionId${data.questionID}`);
                value.classList.remove("synced-answer");
                value.classList.remove("marked");
                value.classList.remove('answered');
              } else if (this.sectionList[i].questionList[j].options[k].checked == false) {
                let value = document.getElementById(`questionId${data.questionID}`);
                value.classList.remove("synced-answer");
                value.classList.remove("marked");
                value.classList.remove('answered');
              } else {
                console.log("no record found");

              }
            }
          }
          if (this.sectionList[i].questionList[j].mode == "Descriptive") {
            this.sectionList[i].questionList[j].descriptiveAnswer = "";
            let value = document.getElementById(`questionId${data.questionID}`);
            value.classList.remove("synced-answer");
            value.classList.remove("marked");
            value.classList.remove('answered');
          }
          this.dbService.update(this.answerDbTableName, this.sectionList[i].questionList[j])
        }
      }
    }
  }
  markForReview(user: any) {
    for (let i = 0; i < this.sectionList.length; i++) {
      for (let j = 0; j < this.sectionList[i].questionList.length; j++) {
        if (this.sectionList[i].questionList[j].questionID == user.questionID) {
          if (this.sectionList[i].questionList[j].mode == "Single") {

            if (user.optionID == undefined || user.optionID == null || user.optionID == '') {
              this.toastr.warning('please select any option for Review');
            } else {

              if (this.sectionList[i].questionList[j].ismarked == true) {

                this.sectionList[i].questionList[j].ismarked = false;
                let insertData = {
                  examCode: this.examCode,
                  subCode: this.subCode,
                  eNo: this.eNo,
                  slotId: this.slotId,
                  questionNo: user.questionNo,
                  questionID: user.questionID,
                  optionID: [parseInt(user.optionID)],
                  isSynced: 'No',
                  sectionName: user.sectionName,
                  sectionCode: user.sectionCode,
                  anserSyncTo: user.anserSyncTo,
                  answerGivenTime: new Date().toISOString(),
                  mode: user.mode,
                  // timeSpend: timeSpend,
                  ismarked: false,
                  setNo: user.setNo,
                };
                this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
                  let value = document.getElementById(`questionId${insertData.questionID}`);
                  value.classList.remove("synced-answer");
                  value.classList.remove('marked');
                  value.classList.add("answered");
                });
              } else {

                this.sectionList[i].questionList[j].ismarked = true;
                let insertData = {
                  examCode: this.examCode,
                  subCode: this.subCode,
                  eNo: this.eNo,
                  slotId: this.slotId,
                  questionNo: user.questionNo,
                  questionID: user.questionID,
                  optionID: [parseInt(user.optionID)],
                  isSynced: 'No',
                  sectionName: user.sectionName,
                  sectionCode: user.sectionCode,
                  anserSyncTo: user.anserSyncTo,
                  answerGivenTime: new Date().toISOString(),
                  mode: user.mode,
                  ismarked: true,
                  setNo: user.setNo,
                  // timeSpend: user.timeSpend
                };
                this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
                  let value = document.getElementById(`questionId${insertData.questionID}`);
                  value.classList.remove("synced-answer");
                  value.classList.remove("answered");
                  value.classList.add('marked');
                });
              }
            }
          }
          if (this.sectionList[i].questionList[j].mode == "Multiple") {
            let markforReviewMuliOptionChecked = []
            for (let k = 0; k < this.sectionList[i].questionList[j].options.length; k++) {
              markforReviewMuliOptionChecked.push(this.sectionList[i].questionList[j].options[k].checked)
              if (k + 1 === this.sectionList[i].questionList[j].options.length) {
                if (markforReviewMuliOptionChecked.includes(true)) {
                  if (this.sectionList[i].questionList[j].ismarked == true) {
                    this.sectionList[i].questionList[j].ismarked = false;
                    let insertData = {
                      examCode: this.examCode,
                      subCode: this.subCode,
                      eNo: this.eNo,
                      slotId: this.slotId,
                      questionNo: user.questionNo,
                      questionID: user.questionID,
                      optionID: [],
                      isSynced: "No",
                      sectionName: user.sectionName,
                      sectionCode: user.sectionCode,
                      anserSyncTo: user.anserSyncTo,
                      answerGivenTime: new Date().toISOString(),
                      mode: user.mode,
                      ismarked: false,
                      setNo: user.setNo,
                    }
                    let options = user.options;
                    options.forEach(function (k: any) {
                      if (k.checked == true) {
                        insertData.optionID.push(parseInt(k.id))
                      }
                    });


                    this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
                      let value = document.getElementById(`questionId${insertData.questionID}`);
                      value.classList.remove("synced-answer");
                      value.classList.remove("marked");
                      value.classList.add('answered');
                    });
                  } else {
                    this.sectionList[i].questionList[j].ismarked = true;
                    let insertData = {
                      examCode: this.examCode,
                      subCode: this.subCode,
                      eNo: this.eNo,
                      slotId: this.slotId,
                      questionNo: user.questionNo,
                      questionID: user.questionID,
                      optionID: [],
                      isSynced: "No",
                      sectionName: user.sectionName,
                      sectionCode: user.sectionCode,
                      anserSyncTo: user.anserSyncTo,
                      answerGivenTime: new Date().toISOString(),
                      mode: user.mode,
                      ismarked: true,
                      setNo: user.setNo,
                    }

                    let options = user.options;
                    options.forEach(function (k: any) {
                      if (k.checked == true) {
                        insertData.optionID.push(parseInt(k.id))
                      }
                    });
                    this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
                      let value = document.getElementById(`questionId${insertData.questionID}`);
                      value.classList.remove("synced-answer");
                      value.classList.remove("answered");
                      value.classList.add('marked');
                    });
                  }
                } else {
                  this.toastr.warning('please select any option for Review');
                }
              }
            }
          }
          if (this.sectionList[i].questionList[j].mode == "Descriptive") {
            if (user.descriptiveAnswer == undefined || user.descriptiveAnswer == null || user.descriptiveAnswer == '') {
              this.toastr.warning('Please write your answer before marking it for review');
            } else {
              if (this.sectionList[i].questionList[j].ismarked == true) {
                this.sectionList[i].questionList[j].ismarked = false;
                let insertData = {
                  examCode: this.examCode,
                  subCode: this.subCode,
                  eNo: this.eNo,
                  slotId: this.slotId,
                  questionNo: user.questionNo,
                  questionID: user.questionID,
                  // optionID: [parseInt(user.optionID)],
                  isSynced: 'No',
                  sectionName: user.sectionName,
                  sectionCode: user.sectionCode,
                  anserSyncTo: user.anserSyncTo,
                  answerGivenTime: new Date().toISOString(),
                  mode: user.mode,
                  // timeSpend: timeSpend,
                  ismarked: false,
                  setNo: user.setNo,
                };
                this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
                  let value = document.getElementById(`questionId${insertData.questionID}`);
                  value.classList.remove("synced-answer");
                  value.classList.remove('marked');
                  value.classList.add("answered");
                });
              } else {
                this.sectionList[i].questionList[j].ismarked = true;
                let insertData = {
                  examCode: this.examCode,
                  subCode: this.subCode,
                  eNo: this.eNo,
                  slotId: this.slotId,
                  questionNo: user.questionNo,
                  questionID: user.questionID,
                  // optionID: [parseInt(user.optionID)],
                  isSynced: 'No',
                  sectionName: user.sectionName,
                  sectionCode: user.sectionCode,
                  anserSyncTo: user.anserSyncTo,
                  answerGivenTime: new Date().toISOString(),
                  mode: user.mode,
                  ismarked: true,
                  setNo: user.setNo,
                };
                this.dbService.update(this.answerDbTableName, insertData).subscribe((storeData) => {
                  let value = document.getElementById(`questionId${insertData.questionID}`);
                  value.classList.remove("synced-answer");
                  value.classList.remove("answered");
                  value.classList.add('marked');
                });
              }
            }
          }
        }

      }
    }
  }

  textChanged($event: any, answerLength) {
    if ($event.editor.getLength() > answerLength) {
      $event.editor.deleteText(answerLength, $event.editor.getLength());
    }
  }
  displayedColumns: string[] = ['Section', 'NoOfQuestions', 'Answered', 'NotAnswered', 'AnsweredAndMarkedForReview', 'NotVisited'];
  loadSummaryData() {
    this.summaryFooter = true;
    this.submitFooter = false;
    this.summaryTable = true;
    this.dbService.getAll('questions').subscribe((questions) => {
      this.questionSummary = questions
      this.dbService.getAll(this.answerDbTableName).subscribe((answers) => {
        this.answerSummary = answers
        this.questionSummary[0].sectionList.map(item => {
          let i = 0;
          let j = 0;
          let k = 0;
          let answerPresent = 0;
          if (this.answerSummary.length == 0) {
            item.totalAnswered = 0;
            item.totalAnsweredMarked = 0;
          }
          else {
            this.answerSummary.map(data => {
              if (item.sectionCode == data.sectionCode) {
                answerPresent++;
                if (data.ismarked) {
                  item.totalAnswered = ++i;
                  item.totalAnsweredMarked = ++j
                }
                else {
                  item.totalAnswered = ++i;
                  item.totalAnsweredMarked = j;
                }
              }

            })
            if (answerPresent === 0) {
              item.totalAnswered = 0;
              item.totalAnsweredMarked = 0;
            }
          }
          this.questionListSummary = JSON.parse(this.questionSummary[0].questions)
          let listItem = []
          this.questionListSummary.map(list => {
            if (list.sectionCode === item.sectionCode) {
              item.noOfQuestions = ++k;
              item.notAnswered = item.noOfQuestions - item.totalAnswered
              listItem.push(list)
              item.questionListSummary = listItem
            }
          })
          this.questionListSummary.map(list => {
            this.answerSummary.map(data => {
              if (data.questionID === list.questionID) {
                list.ismarked = data.ismarked;
                list.isSynced = data.isSynced;
                if (list.isSynced == 'No' && list.ismarked === false) {
                  list.notSyncedMarked = true;
                }
                if (list.isSynced === 'Yes' && list.ismarked === false) {
                  list.syncedNotMarked = 'Yes'
                }
              }
            })
          })
        })
        this.summaryData = this.questionSummary[0].sectionList
      });
    });
  }

  submitButton() {
    this.loadSummaryData()
    this.summaryFooter = false;
    this.submitFooter = true;
    this.summaryTable = true;
  }
}
export const QuillConfiguration = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    // ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    // ['link'],
    // ['clean'],
  ],
}
