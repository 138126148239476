import { ToastrService } from 'ngx-toastr';
import { ExamService } from './../../../services/exam.service';
import { timer } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {

  @Input() examCode: any;
  @Input() subCode: any;
  eNo:string;

  constructor(
    private service: ExamService, 
    private toastr: ToastrService,
    private authService: AuthService
  ) { 
    this.eNo = this.authService.getENo()
  }

  timerSubscription: any;
  hour: any;
  minute: any;
  second: any;
  slotId: string;
  ngOnInit(): void {    
    this.hour = 0;
    this.minute = 0;
    this.second = 0;
    this.slotId = this.authService.getSlotId();
    this.service.getExamTime1(this.examCode, this.subCode, this.slotId,this.eNo).subscribe( result => {
              this.startTimer(result.duration);
    }, error => this.toastr.error(error.statusText, error.status));
  }

  startTimer(duration: any) {
    this.hour = duration.hour;
    this.minute = duration.minute;
    this.second = duration.second;
    this.timerSubscription = timer(0, 1000).subscribe(x => {      
          if(this.second === 0) {
              if(this.minute === 0) {
                if(this.second === 0 && this.minute === 0 && this.hour === 0) {
                        this.timerSubscription.unsubscribe();
                } else {
                  this.hour = this.hour - 1;
                  this.minute = 59;
                }
              }
              if(this.second === 0 && this.minute === 0 && this.hour === 0) {
                // this.timerSubscription.unsubscribe();
                // this.toastr.warning('Thank you. Your exam time is over.');
              } else {
                this.minute = this.minute - 1;
                this.second = 60;
              }
          } else {
            this.second = this.second - 1;
          }
      });
  }


  ngOnDestroy() {
        this.timerSubscription.unsubscribe();
  }

}
