import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liveliness-detection',
  templateUrl: './liveliness-detection.component.html',
  styleUrls: ['./liveliness-detection.component.css']
})
export class LivelinessDetectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
