import { PersonalService } from './../../../services/personal.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { serverUrl } from 'src/environments/environment';
import { ExamService } from 'src/app/services/exam.service';
import { ToastrService } from 'ngx-toastr';

declare const aware: any;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  apiForSync: any;
  userPicUrls: any;
  userDetails: any;
  eNo: string;
  apiForCameraProblem: any;
  isProctoringUnable: boolean;
  examCode: string;
  subCode: string;
  anserSyncTo: any;
  constructor(
    private authService: AuthService,
    private loader: NgxUiLoaderService,
    private stepService: PersonalService,
    private service: ExamService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.examCode = this.authService.getExamCode();
    this.subCode = this.authService.getSubCode();

    this.service.getIsProctoringEnableOrNot(this.authService.getExamCode(), this.authService.getSubCode()).subscribe(result => {
      this.isProctoringUnable = result.isProctoringEnable == 'Yes' ? true : false;
      this.eNo = this.authService.getENo();
      if (this.isProctoringUnable) {
        this.loadDependencies();
      }
    })
  }
  async loadDependencies() {
    this.service.GetSyncToKafkaOrMongo(this.examCode, this.subCode).subscribe(async result => {
      this.loader.start();
      if (!this.stepService.getIsLoadedAllDependencies()) {
        const modelType = this.authService.getModelType();
        // ==========================================================================
        // let stdImg: any;
        // if (stdImg == '' || stdImg == undefined || stdImg == null) {
        //   stdImg = `${serverUrl}/studentPics/default.jpg`;
        //   await aware.waitToLoadModels([stdImg], modelType);
        // } else {
        //   stdImg = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;
        //   await aware.waitToLoadModels([stdImg], modelType);
        // }

        // =============================================================================================
        await aware.waitToLoadModels([`${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`], modelType);
        // await aware.waitToLoadModels([`${serverUrl}/studentPics/${this.eNo}.jpg`], modelType);
        this.userDetails = {
          eNo: this.authService.getENo(),
          examCode: this.authService.getExamCode(),
          subCode: this.authService.getSubCode(),
          slotId: this.authService.getSlotId(),
          anserSyncTo: result.syncType
        }
        this.apiForSync = `${serverUrl}/proctoring/syncViolationProctoringData`;
        await aware.initializeProctor(new Date(), this.apiForSync, this.userDetails);
        this.stepService.setIsLoadedAllDependencies();
      }
      await aware.startProcess("video", "violationMsg");
      this.loader.stop();
    }), error => {
      this.toastr.error(error.statusText, error.status);
    }
  }

  ngOnDestroy() {
    if (this.isProctoringUnable) {
      aware.endProcess();
    }
  }

}
