import { Component, OnInit, NgZone, } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { serverUrl } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { ExamService } from './../../services/exam.service';
import { ToastrService } from 'ngx-toastr';

declare const window: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  token: any;
  examCode: string;
  subCode: string;
  eNo: string;
  slotId: string;
  isSmartlockEnable: any;
  constructor(
    private router: Router,
    private authService: AuthService,
    private ngZone: NgZone,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private ExamService: ExamService
  ) {
    this.examCode = this.authService.getExamCode();
    this.subCode = this.authService.getSubCode();
    this.token = this.authService.getToken();
    this.eNo = this.authService.getENo();
    this.slotId = this.authService.getSlotId();
  }

  ngOnInit(): void {
    this.ExamService.getExamDetail(this.examCode, this.subCode).subscribe(examResult => {
      if (examResult) {
        if (examResult.isSmartlockEnable === "Yes") {
          this.isSmartlockEnable = examResult.isSmartlockEnable;
          this.ExamService.setSmartlockEnable({ examCode: this.examCode, subCode: this.subCode, eNo: this.eNo, slotId: this.slotId }).subscribe(resultData => {
            if (resultData) {
              window.create_hs(this.token);
            }
          })
        }
      }
    })
    window['angularComponentReference'] = {
      component: this, zone: this.ngZone, loadAngularFunction: (websocketData: any) => {
        this.dialogData({ socketData: websocketData.status })
      }
    };
  }
  gotoInstuction() {
    if (this.isSmartlockEnable === "Yes") {
      this.ExamService.isSmartLockEnable(this.examCode, this.subCode, this.eNo, this.slotId).subscribe(resultData => {
        if (resultData?.isSmartlockEnable === "Yes") {
          this.router.navigate(['/student/instructions']);
        } else {
          this.toastr.warning("Please Ensure SmartLockApp is installed in Your System Then Try to Login")
          // window.logout();
          this.authService.logout();
          window.location.replace(`${serverUrl}/home.html`);
        }
      })
    } else {
      this.router.navigate(['/student/instructions']);
    }
  }
  logout() {
    if (this.isSmartlockEnable === "Yes") {
      this.authService.logout();
      window.logout();
      window.location.replace(`${serverUrl}/home.html`);
    } else {
      this.authService.logout();
      window.location.replace(`${serverUrl}/home.html`);
    }
  }
  dialogData(socketData: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '600px';
    dialogConfig.data = socketData;
    // dialogConfig.height='500px'
    this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe(() => { });
  }

  //=========================use for Auto Redirect To instruction Page====================
  // dialogData(socketData: any) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.disableClose = true;
  //   dialogConfig.width = '800px';
  //   // dialogConfig.height='500px'
  //   if (socketData == "success") {
  //     this.router.navigate(['/admin/viewExamDetails']);
  //   } else if (socketData == "failed" || socketData == "close" || socketData == "error") {
  //     dialogConfig.data = socketData;
  //     this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe(() => { });
  //   }else{
  //     dialogConfig.data = "Socket Connection Disconnect";
  //     this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe(() => { });
  //     this.logout();
  //   }
  // }

}

