import { Injectable,Injector } from '@angular/core';
import { HttpInterceptor} from '@angular/common/http';
import { AuthService} from './auth.service';
//import { serverUrl } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TokenIntercepterService implements HttpInterceptor {

  constructor(private injector: Injector
    ) { }
  intercept(req, next){
    let authService= this.injector.get(AuthService)
    let tokenizedReq=req.clone({
      headers: req.headers.set('Authorization', authService.getToken())
    })
  return next.handle(tokenizedReq)
  }
}
