import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serverUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  violationSyncTo: string;

  constructor(private http: HttpClient) { }
  getQuestion(examCode: string, subCode: string, slotId: string, eNo: string, setNo: any): Observable<any> {
    return this.http.get(`${serverUrl}/student/getQuestion?examCode=${examCode}&subCode=${subCode}&slotId=${slotId}&eNo=${eNo}&setNo=${setNo}`);
  }
  instruction(examCode: string, subCode: string): Observable<any> {
    return this.http.get(`${serverUrl}/student/getInstruction/${examCode}/${subCode}`);
  }
  addAnswer(data: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/addAnswer`, data);
  }
  getExamTime(examCode: any, subCode: any, slotId: any): Observable<any> {
    return this.http.get(`${serverUrl}/student/getExamTime/${examCode}/${subCode}/${slotId}`);
  }
  getExamTime1(examCode: any, subCode: any, slotId: any,eNo:any): Observable<any> {
    return this.http.get(`${serverUrl}/student/getExamTime1/${examCode}/${subCode}/${slotId}/${eNo}`);
  }
  getStudentInfo(eNo: string): Observable<any> {
    return this.http.get(`${serverUrl}/student/getStudentInfo/${eNo}`);
  }
  getExamDetail(examCode: string, subCode: string): Observable<any> {
    return this.http.get(`${serverUrl}/student/getExamDetail/${examCode}/${subCode}`);
  }
  getAnswerSyncMode(examCode: string, subCode: string): Observable<any> {
    return this.http.get(`${serverUrl}/student/getAnswerSyncMode/${examCode}/${subCode}`);
  }
  submitFinalAnswer(allData: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/submitFinalAnswer/`, allData);
  }
  getServerCurrentTime(): Observable<any> {
    return this.http.get(`${serverUrl}/getServerCurrentTime/`);
  }
  getAuthenticationMessages(examCode: string, subCode: string): Observable<any> {
    return this.http.get(`${serverUrl}/student/getAuthenticationMessages/${examCode}/${subCode}`);
  }
  getRandomTimeForLiveness(examCode: string, subCode: string): Observable<any> {
    return this.http.get(`${serverUrl}/student/getRandomTimeForLiveness/${examCode}/${subCode}`);
  }
  addFailedAuth(data: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/addFailedAuth`, data);
  }
  addSuccessAuth(data: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/addSuccessAuth`, data);
  }
  updateExamStartTime(data: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/updateExamStartTime`, data);
  }
  getIsProctoringEnableOrNot(examCode: any, subCode: any): Observable<any> {
    return this.http.get(`${serverUrl}/student/getIsProctoringEnableOrNot/${examCode}/${subCode}`);
  }
  // ============================================
  getIsFaceAuthenticationEnableOrNot(examCode: any, subCode: any): Observable<any> {
    return this.http.get(`${serverUrl}/student/getIsFaceAuthenticationEnableOrNot/${examCode}/${subCode}`);
  }
  // ==================================================
  // ==============GetSyncToKafkaOrMongo
  GetSyncToKafkaOrMongo(examCode: string, subCode: string): Observable<any> {
    return this.http.get(`${serverUrl}/proctoring/GetSyncToKafkaOrMongo/${examCode}/${subCode}`);
  }
  resetAnswer(data: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/resetAnswer`, data);
  }
  getAuthenticateUser(allData: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/getAuthenticateUser/`, allData);
  }
  setSmartlockEnable(allData: any): Observable<any> {
    return this.http.post(`${serverUrl}/student/setSmartlockEnable/`, allData);
  }
  isSmartLockEnable(examCode: any, subCode: any,eNo:any, slotId: any): Observable<any> {
    return this.http.get(`${serverUrl}/student/isSmartLockEnable/${examCode}/${subCode}/${eNo}/${slotId}`);
  }
  //get csrf token
  // getCsrfToken(): Observable<any> {
  //   return this.http.get(`${serverUrl}/csrf`);
  // }
}