<div class="container">
	<div class="row logobar">
		<div class="col-md-8">
			<img src="assets/new-template/images/logo.png" />
		</div>
		<div class="col-md-4">
			<h5>Welcome, Bhimasen Sahoo</h5>
			<h6>Enrollment No. - 1645</h6>
			<button class="btn" (click)="logout();"><a >Logout</a></button>
		</div>
	</div>		
</div>