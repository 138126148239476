import { PersonalService } from './services/personal.service';
import { ExamService } from './services/exam.service';
import { CookieService } from 'ngx-cookie-service';
import { ExamModule } from './exam/exam.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenIntercepterService } from './services/token-intercepter.service';

export function migrationFactory() {
  return {
    1: (db: any, transaction: any) => {
      const store = transaction.objectStore('questions');
      store.createIndex('Value', ['examCode', 'subCode', 'eNo'], { unique: false });
    },
    3: (db: any, transaction: any) => {
      const store = transaction.objectStore('answers');
      store.createIndex('isSynced', 'isSynced', { unique: false });
    },
    4: (db: any, transaction: any) => {
      const store = transaction.objectStore('examDetails');
      store.createIndex('Value', ['examCode', 'subCode', 'eNo'], { unique: false });
    }
  };
}
const dbConfig: DBConfig = {
  name: 'onlineExam',
  version: 3,
  objectStoresMeta: [{
    store: 'questions',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: []
  }, {
    store: 'answers',
    storeConfig: { keyPath: 'questionID', autoIncrement: false },
    storeSchema: []
  }, {
    store: 'examDetails',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: []
  }],
  migrationFactory
};


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
 HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    ExamModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    NgxUiLoaderModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [AuthService, ExamService, PersonalService, CookieService, DatePipe,,{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenIntercepterService,
    multi:true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
