import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ExamRoutingModule } from './exam-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamLayoutComponent } from './layout/exam-layout/exam-layout.component';
import { ExamHeaderComponent } from './layout/exam-header/exam-header.component';
import { ExamFooterComponent } from './layout/exam-footer/exam-footer.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { VideoComponent } from './question-answer/video/video.component';
import { TimerComponent } from './question-answer/timer/timer.component';
import { SyncAnswerComponent } from './question-answer/sync-answer/sync-answer.component';
import { LivelinessDetectionComponent } from './question-answer/liveliness-detection/liveliness-detection.component';
import { ExamHeaderLinkComponent } from './layout/exam-header-link/exam-header-link.component';
import { QuillModule } from 'ngx-quill';
import { MatTableModule } from '@angular/material/table';
import { HomeComponent } from './home/home.component';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [
    ExamLayoutComponent,
    ExamHeaderComponent,
    ExamFooterComponent,
    InstructionsComponent,
    QuestionAnswerComponent,
    VideoComponent,
    TimerComponent,
    SyncAnswerComponent,
    LivelinessDetectionComponent,
    ExamHeaderLinkComponent,
    HomeComponent,
    DialogComponent
  ],
  imports: [
    CommonModule,
    ExamRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatDialogModule,
    QuillModule.forRoot()
  ]
})
export class ExamModule { }
