import { Injectable } from '@angular/core';
declare const aware: any;

@Injectable({
  providedIn: 'root'
})
export class PersonalService {

  constructor() { }

  isLoadedAllDependencies: boolean = false;
  isCompleteInstruction: boolean = false;
  isFaceMatched: boolean = false;
  isCalibrationDone: boolean = true;
  isCompleteAudioCheck: boolean = true;
  isCompleteDontDoThis: boolean = true;
  isCompleteUsbList: boolean = true;
  
  setIsLoadedAllDependencies() {
      this.isLoadedAllDependencies = true;
  }
  getIsLoadedAllDependencies(): boolean {
      return this.isLoadedAllDependencies;
  }
  setIsFaceMatched() {
    this.isFaceMatched = true;
  }
  getIsFaceMatched(): boolean {
      return this.isFaceMatched;
  }
  setIsCompleteDontDoThis() {
        this.isCompleteDontDoThis = true;
  }
  getIsCompleteDontDoThis(): boolean {
        return this.isCompleteDontDoThis;
  }
  setIsCompleteInstruction() {
        this.isCompleteInstruction = true;
  }
  getIsCompleteInstruction(): boolean {
        return this.isCompleteInstruction;
  }
  setIsCompleteAudioCheck() {
        this.isCompleteAudioCheck = true;
  }
  getIsCompleteAudioCheck(): boolean {
        return this.isCompleteAudioCheck;
  }
  setIsCompleteVerifyDetail() {
        this.isFaceMatched = true;
  }
  getIsCompleteVerifyDetail(): boolean {
        return this.isFaceMatched;
  }
  setIsCompleteUsbList() {
        this.isCompleteUsbList = true;
  }
  getIsCompleteUsbList(): boolean {
        return this.isCompleteUsbList;
  }
  setIsCompleteCalibration() {
        this.isCalibrationDone = true;
  }
  getIsCompleteCalibration(): boolean {
        return this.isCalibrationDone;
  }
  isCompletedAllSteps(): boolean {
        return this.isFaceMatched
        && this.isCalibrationDone      
        && this.isCompleteDontDoThis
        && this.isCompleteInstruction
      //   && this.isCompleteUsbList
        && this.isCompleteAudioCheck
  }
}
