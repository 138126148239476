<div class="container-fluid midcontents" (contextmenu)="onRightClick($event)">
    <div class="container">
        <div class="row logobar">
            <div class="col-md-7">
                <img src="assets/new-template/images/logo.png" />
            </div>
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-8">
                        <p style="font-size: 15px;"><strong>Welcome, {{ userName }}</strong></p>
                        <p style="font-size: 15px;"><strong>Enrollment No. - {{ eNo }}</strong></p>
                        <p style="font-size: 15px;"><strong>Examination Name ({{examCode}}) - {{examName}}</strong></p>
                        <p style="font-size: 15px;"><strong>Subject Name ({{subCode}}) - {{examInfo}}</strong>
                        </p>
                        <p class="text-danger" *ngIf="isNetworkIssue == true" style="font-size: 20px;">
                             <strong>Offline</strong>
                        </p>
                        
                        <!-- <h6>Slot Id - {{slotId}}</h6> -->
                        <!-- <h6>Set No. - {{setDet}}</h6> -->
                    </div>
                    <div class="col-md-4">
                        <img style=" height: 111px; width: 132px; margin-left: 5px;" alt="" src="{{ studentInfo }}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container exambox">
        <div class="row examboxs">
            <div class="col-md-5 time">
                <app-video></app-video>
            </div>
            <div class="col-md-7 sbmtbtn">
                <button class="btn" data-toggle="modal" data-target="#summaryModal"
                    (click)="submitButton()"><a>Submit</a></button>
                <button class="btn" style="margin-right: 14px;background-color: orange !important;" data-toggle="modal"
                    data-target="#summaryModal" (click)="loadSummaryData()"><a>View Summary</a></button>
                <button class="btn times" style="padding: 8px 10px !important;">Time Remaining : {{ hour }} hour {{
                    minute }} min
                    {{ second }} sec</button>
            </div>
        </div>

        <div class="row nopad" id="accordion-style-1">

            <div class="col-12 mx-auto">
                <div class="accordion" id="accordionExample">
                    <div class="card" *ngFor="let section of sectionList; let k = index">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" id="collapseButton{{k}}"
                                    (click)="changesInCollapse(k)" data-toggle="collapse" aria-expanded="false"
                                    aria-controls="collapseFour">
                                    Section : {{ section.sectionName }}
                                </button>
                            </h5>
                        </div>
                        <div id="collapse{{k}}" class="fade collapse" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8 borderri">
                                        <div class="questionbox">
                                            <tr
                                                *ngFor="let user of section.questionList | slice : section.x : section.y; let f = index">
                                                <td>
                                                    <h4> <b>{{ user.questionNo }} :</b> <b style="color: blue;">Time
                                                            Taken: {{display }}</b></h4>
                                                    <p> {{ user.question }} </p>
                                                    <p><img *ngIf="user.imgQuestion" [src]="user.imgQuestion"
                                                            height="350" width="600"></p>
                                                    <div class="form-group">
                                                        <div style="display: flex;">
                                                            <div class="col-8">
                                                                <label *ngIf="user.mode == 'Descriptive'">Answer
                                                                    (Maximum Characters Allowed -
                                                                    {{user.answerSize}})<span
                                                                        class="asteric-mark">*</span>:</label>
                                                            </div>
                                                            <div class="col-4"
                                                                style="float: right; margin-left: 5.65rem;">
                                                                <button class="btn btnconfirm"
                                                                    *ngIf="user.mode === 'Descriptive'"
                                                                    (click)="saveDescriptive(user,display)">Save</button>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <quill-editor *ngIf="user.mode == 'Descriptive'"
                                                                class="content-editor"
                                                                [placeholder]="'Write your answer here'"
                                                                [modules]="quillConfiguration"
                                                                (onContentChanged)="textChanged($event, user.answerSize)"
                                                                [(ngModel)]="user.descriptiveAnswer"
                                                                id="descriptiveAnswer" contenteditable="true"
                                                                [styles]="{'min-height': '120px'}"> </quill-editor>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="user.mode != 'Descriptive'">
                                                        <form *ngFor="let option of user.options; let j = index">
                                                            <input type="radio" *ngIf="user.mode === 'Single'"
                                                                [(ngModel)]="user.optionID"
                                                                [ngModelOptions]="{standalone: true}"
                                                                value="{{ option.id }}" id="option{{ k }}{{ j }}"
                                                                (change)="syncAnswer(user,display);">
                                                            <input type="checkbox" *ngIf="user.mode === 'Multiple'"
                                                                id="option{{ k }}{{ j }}" [checked]="option.checked"
                                                                (change)="syncMultiAnswer(user,display);"
                                                                [(ngModel)]="option.checked"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [ngModelOptions]="{standalone: true}">
                                                            <label for="option{{ k }}{{ j }}">&nbsp; {{ option.text
                                                                }}
                                                                <img *ngIf="option.img" [src]="option.img" height="350"
                                                                    width="600">
                                                            </label><br />
                                                        </form>
                                                    </div>
                                                    <button style="position: absolute; margin-top:2.80rem;"
                                                        class="btn btn3" (click)="resetAnswer(user)">
                                                        <a style="margin-top: 7rem;">Reset</a>
                                                    </button><br /><br />

                                                    <button
                                                        style="position: absolute; margin-left: 8.2rem; background-color: #b8dd11 !important;"
                                                        class="btn"
                                                        *ngIf="user.ismarked == 'NA' || user.ismarked == false"
                                                        (click)="markForReview(user)">Review
                                                    </button>
                                                    <button class="btnconfirm"
                                                        style="position: absolute; margin-left: 8.2rem;"
                                                        *ngIf="user.ismarked === true"
                                                        (click)="markForReview(user)">Confirm</button>
                                                </td>
                                            </tr>
                                            <div class="row">
                                                <button style="margin-left: 17.4rem;" class="btn btn1" (click)="prev(k)"
                                                    [disabled]="questionShowing === 0 && k === 0">
                                                    <a>Previous</a>
                                                </button>
                                                <button class="btn btn2" (click)="next(k)">
                                                    <a>Next</a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <ol>
                                                <li><a class="topans">Answered but not Synced</a></li>
                                                <li><a class="topunans">Not Answered</a></li>
                                                <li><a class="topmark">Answered and Synced</a></li>
                                                <li><a class="toprvw">Marked For Review</a></li>
                                            </ol>
                                        </div>
                                        <ul></ul>
                                        <ul class="qstnHover" *ngFor="let x of section.questionList; let j = index">
                                            <li class="questionBtn" id="questionId{{x.questionID}}"
                                                (click)="gotoQuestionNo(x.sectionCode, j)" data-toggle="tooltip"
                                                data-placement="top" id="hints" title="{{ x.question }}">
                                                <a>{{ x.slNo }}</a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="summaryModal">
    <div class="modal-dialog" style="margin-right: 50%;">
        <div class="modal-content" style="width: 64vw; min-height: 40vw;">
            <div class="modal-header bg-primary">
                <h5 class="modal-title" *ngIf="summaryFooter">View Summary</h5>
                <h5 class="modal-title" *ngIf="submitFooter">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <ol>
                    <li><a class="topans">Answered but not Synced</a></li>
                    <li><a class="topunans">Not Answered</a></li>
                    <li><a class="topmark">Answered and Synced</a></li>
                    <li><a class="toprvw">Marked For Review</a></li>
                </ol>
                <table mat-table [dataSource]="summaryData" class="mat-elevation-z8" multiTemplateDataRows>
                    <ng-container matColumnDef="Section">
                        <th mat-header-cell *matHeaderCellDef style="text-align: left; font-weight: 500;"> Section </th>
                        <td mat-cell *matCellDef="let element" style="text-align: left; font-weight: 400;">
                            {{element.sectionName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="NoOfQuestions">
                        <th mat-header-cell *matHeaderCellDef style="text-align: left; font-weight: 500;"> No Of
                            Questions </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; font-weight: 400;">
                            {{element.noOfQuestions}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Answered">
                        <th mat-header-cell *matHeaderCellDef style="text-align: left; font-weight: 500;">Total Answered
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; font-weight: 400;">
                            {{element.totalAnswered}}</td>
                    </ng-container>

                    <ng-container matColumnDef="NotAnswered">
                        <th mat-header-cell *matHeaderCellDef style="text-align: left; font-weight: 500;"> Not Answered
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; font-weight: 400;">
                            {{element.notAnswered}} </td>
                    </ng-container>

                    <ng-container matColumnDef="AnsweredAndMarkedForReview">
                        <th mat-header-cell *matHeaderCellDef style="text-align: left; font-weight: 500;"> Answered And
                            Marked For Review </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; font-weight: 400;">
                            {{element.totalAnsweredMarked}} </td>
                    </ng-container>

                    <ng-container matColumnDef="NotVisited">
                        <th mat-header-cell *matHeaderCellDef style="text-align: left; font-weight: 500;"> Not Visited
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; font-weight: 400;">
                            {{element.NotVisited}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4" style="text-align: center; font-weight: 400;">No data matching
                            the filter.</td>
                    </tr>

                    <ng-container matColumnDef="groupHeader">
                        <td colspan="6" mat-cell *matCellDef="let element; let i = dataIndex;">
                            <ul class="qstnHover">
                                <li [ngClass]="{'marked-summary':x.ismarked == true, 'not-answered-summary': x.ismarked == 'NA', 'synced-answer-summary': x.syncedNotMarked === 'Yes', 'answered-summary': x.notSyncedMarked === true}"
                                    *ngFor="let x of element.questionListSummary; let j = index" class="questionBtn"
                                    id="questionIdSummary{{x.questionID}}" (click)="gotoQuestionNo(x.sectionCode, j, i)"
                                    data-dismiss="modal" data-toggle="tooltip" data-placement="top" id="hints"
                                    title="{{ x.question }}">
                                    <a>{{ x.questionNo }}</a>
                                </li>
                            </ul>
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: ['groupHeader'];"> </tr>

                </table>
                <div style="margin-top: 3%; text-align: center;">
                    <h4 style="color: blue;" *ngIf="submitFooter"> There is still time for the test to finish. Do you
                        still want to exit?</h4>
                </div>
            </div>
            <div class="modal-footer" *ngIf="submitFooter">
                <button type="button" class="btn" style="background-color: green !important;" data-dismiss="modal">No, I
                    want to continue the test</button>&nbsp;&nbsp;
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="submit();">YES, I want to
                    finish and exit</button>
            </div>
            <div class="modal-footer" *ngIf="summaryFooter">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;&nbsp;
            </div>
        </div>
    </div>
</div>