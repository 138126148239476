import { interval } from 'rxjs';
import { PersonalService } from './../../services/personal.service';
import { ExamService } from './../../services/exam.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { serverUrl } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { __await } from 'tslib';

declare const window: any;
declare const aware: any;

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {

  examCode: string;
  subCode: string;
  eNo: string;
  public result: Array<any>
  public dataa: any;
  studentInfo: any;
  examInfo: any;
  matchPictureMsg: any;
  videotag: any;
  localstream: any;
  pictureIsMatch: boolean;
  intervalSubscription: any;
  faceMatchTime: number;
  faceNotMatchMessage: string;
  faceMatchMessage: string;
  anserSyncTo: any;
  faceAuthEnableOrNot: boolean = false; //for face authentication Enable Or Disable
  getIsFaceAuthenticationEnableOrNot: boolean;
  n = <any>navigator;
  isSmartlockEnable: any;
  constructor(
    private service: ExamService,
    private authService: AuthService,
    private stepService: PersonalService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService
  ) {
    this.n.getUserMedia = this.n.getUserMedia || this.n.webkitGetUserMedia || this.n.mozGetUserMedia || this.n.msGetUserMedia;
    // this.getCsrfToken()
    this.examCode = this.authService.getExamCode();
    this.subCode = this.authService.getSubCode();
    this.eNo = this.authService.getENo();
  }

  ngOnInit(): void {
    this.service.getExamDetail(this.examCode, this.subCode).subscribe(examResult => {
      this.isSmartlockEnable = examResult.isSmartlockEnable;
    })
    window.loadStepsFunction();
    this.service.instruction(this.examCode, this.subCode).subscribe(data => {
      this.result = data;
      this.stepService.setIsCompleteInstruction();
    });
    this.initializeFirst();
  }
  //// Get csrf token and add to the browser cookie
  //// getCsrfToken() {
  ////   this.service.getCsrfToken().subscribe(result => {
  ////   });
  //// }
  async initializeFirst() {
    this.studentInfo = {};
    this.examInfo = {};
    this.matchPictureMsg = 'Show your face';
    this.videotag = document.getElementById('testStudentPicture');
    this.eNo = this.authService.getENo();
    let examCode = this.authService.getExamCode();
    let subCode = this.authService.getSubCode();
    this.service.getIsFaceAuthenticationEnableOrNot(this.authService.getExamCode(), this.authService.getSubCode()).subscribe(result => {
      this.getIsFaceAuthenticationEnableOrNot = result.isFaceAuthenticationEnable == 'Yes' ? true : false;

      if (this.getIsFaceAuthenticationEnableOrNot) {
        this.pictureIsMatch = false;
        this.loadAuthenticationMessages(examCode, subCode);
        this.loadStudentFaceInfo(this.eNo);
        this.getExamDetail(examCode, subCode);
        this.checkStudentPictureIsMatchOrNot();
      } else if (!this.getIsFaceAuthenticationEnableOrNot) {
        this.faceAuthEnableOrNot = true;
        this.loadStudentInfo(this.eNo);
        this.getExamDetail(examCode, subCode);
        // ==================================================================================
        // let stdImg: any;
        // if (stdImg == '' || stdImg == undefined || stdImg == null) {
        //   stdImg = `${serverUrl}/studentPics/default.jpg`;
        //   this.studentInfo.imageUrl = stdImg;
        // } else {
        //   stdImg = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;
        //   this.studentInfo.imageUrl = stdImg;
        // }
        this.studentInfo.imageUrl = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;


        // ==================================================================================
        // this.studentInfo.imageUrl = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;
        // this.studentInfo.imageUrl = `${serverUrl}/studentPics/${this.eNo}.jpg`;
      }
    })

  }
  loadAuthenticationMessages(examCode: string, subCode: string) {
    this.service.getAuthenticationMessages(examCode, subCode).subscribe(result => {
      this.faceMatchTime = +result.faceMatchTime;
      this.faceNotMatchMessage = result.faceNotMatchMessage;
      this.faceMatchMessage = result.faceMatchMessage;
      if (this.stepService.getIsFaceMatched()) {
        this.matchPictureMsg = this.faceNotMatchMessage;
      }
    }, error => this.toastr.error(error.statusText, error.status));
  }
  loadStudentFaceInfo(eNo: string) {
    this.service.getStudentInfo(eNo).subscribe(async result => {
      this.studentInfo = result;
      // ==================================================================================
      // let stdImg: any;
      //   if (stdImg == '' || stdImg == undefined || stdImg == null) {
      //     stdImg = `${serverUrl}/studentPics/default.jpg`;
      //     this.studentInfo.imageUrl = stdImg;
      //   } else {
      //     stdImg = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;
      //     this.studentInfo.imageUrl = stdImg;
      //   }
      this.studentInfo.imageUrl = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;


      // let stdImg = `${serverUrl}/student/getStudentImageUrl/${eNo}.jpg`
      // if (stdImg == '' || stdImg == undefined || stdImg == null) {
      //   stdImg = `${serverUrl}/studentPics/default.jpg`
      // }
      // this.studentInfo.imageUrl = stdImg;
      // ==================================================================================
      // this.studentInfo.imageUrl = `${serverUrl}/studentPics/${eNo}.jpg`;
      // this.studentInfo.imageUrl = `${serverUrl}/student/getStudentImageUrl/${eNo}.jpg`;

      if (this.stepService.getIsFaceMatched()) {
        this.pictureIsMatch = true;
      } else {
        if (!this.stepService.getIsLoadedAllDependencies()) {
          this.loader.start();
          this.loadDependenciesFirst();
        } else {
          // this.startCamera();
        }
      }
    });
  }
  loadStudentInfo(eNo: string) {
    this.service.getStudentInfo(eNo).subscribe(async result => {
      this.studentInfo = result;
    });
  }
  verifyStudentStart() {
    if (!this.pictureIsMatch) {
      this.startCamera();
    }
  }
  getExamDetail(examCode: string, subCode: string) {
    this.service.getExamDetail(examCode, subCode).subscribe(result => {
      this.examInfo = result;
    });
  }
  proceed() {
    // this.router.navigate(['/student/audioCheck']);
    this.router.navigate(['/student/questionAnswer']);
  }


  async checkStudentPictureIsMatchOrNot() {
    this.videotag.addEventListener('play', async () => {
      await aware.initialize('testStudentPicture');
      this.ngxLoader.stop();
      const secondsCounter = interval(1500);
      let faceCount = 0;
      let falseMatch = 0;
      this.intervalSubscription = secondsCounter.subscribe(n => {
        aware.match().then((result: any) => {
          if (result.result) {
            faceCount++;
            // falseMatch = 0;
            this.matchPictureMsg = 'Face matching..';
            if (faceCount === 5) {
              this.pictureIsMatch = true;
              this.matchPictureMsg = this.faceMatchMessage;
              this.stopCamera();
              this.stepService.setIsFaceMatched();
              this.authenticationPassed(result);
            }
          } else {
            faceCount = 0;
            falseMatch++;
            if (falseMatch === this.faceMatchTime) {
              this.pictureIsMatch = true;
              this.matchPictureMsg = this.faceNotMatchMessage;
              this.stopCamera();
              this.stepService.setIsFaceMatched();
              this.authenticationFailed(result);
            } else {
              this.matchPictureMsg = 'Sorry, your face not matched. Wait for matching';
            }
          }
        })
      });
    })
  }

  async startCamera() {
    if (this.getIsFaceAuthenticationEnableOrNot) {
      this.n.getUserMedia = (
        this.n.getUserMedia
      )
      // navigator.getUserMedia = (
      //   navigator.getUserMedia
      // );
      this.n.getUserMedia(
        { video: {} },
        stream => {
          this.localstream = stream;
          return this.videotag.srcObject = stream;
        },
        err => {
          console.error(err);
        }
      )
      return;
    }
  }
  stopCamera() {
    if (this.getIsFaceAuthenticationEnableOrNot) {
      if (this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }
      if (!!this.videotag.srcObject) {
        this.videotag.src = "";
        this.videotag.pause();
        this.localstream.getTracks()[0].stop();
        aware.clearCanvas();
        this.videotag.setAttribute("style", "display: none;");
      }
    }
  }


  ngOnDestroy() {
    // if (this.getIsFaceAuthenticationEnableOrNot) {
    this.stopCamera();
    // }
  }
  async loadDependenciesFirst() {
    this.service.GetSyncToKafkaOrMongo(this.examCode, this.subCode).subscribe(async result => {
      const modelType = this.authService.getModelType();
      // ==================================================================================
      // let stdImg: any;
      // if (stdImg == '' || stdImg == undefined || stdImg == null) {
      //   stdImg = `${serverUrl}/studentPics/default.jpg`;
      //   await aware.waitToLoadModels([stdImg], modelType);
      // } else {
      //   stdImg = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;
      //   await aware.waitToLoadModels([stdImg], modelType);
      // }

      // let stdImg = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`;
      // await aware.waitToLoadModels([stdImg], modelType);


      // let stdImg = `${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`
      // if (stdImg == '' || stdImg == undefined || stdImg == null) {
      //   stdImg = `${serverUrl}/studentPics/default.jpg`
      // }
      // await aware.waitToLoadModels([stdImg], modelType);
      // ==================================================================================
      // await aware.waitToLoadModels([`${serverUrl}/studentPics/${this.eNo}.jpg`], modelType);
      await aware.waitToLoadModels([`${serverUrl}/student/getStudentImageUrl/${this.eNo}.jpg`], modelType);
      const userDetails = {
        eNo: this.authService.getENo(),
        examCode: this.authService.getExamCode(),
        subCode: this.authService.getSubCode(),
        slotId: this.authService.getSlotId(),
        anserSyncTo: result.syncType
      }
      const apiForSync = `${serverUrl}/proctoring/syncViolationProctoringData`;
      ////FIXME: new Date() is not recomended. fetch date form server side.
      await aware.initializeProctor(new Date(), apiForSync, userDetails);
      this.stepService.setIsLoadedAllDependencies();
      this.loader.stop();
      // this.startCamera();
    }), error => {
      this.toastr.error(error.statusText, error.status,);
    }
  }
  authenticationPassed(result: any) {
    const data = {
      eNo: this.eNo,
      examCode: this.authService.getExamCode(),
      examName: this.authService.getExamName(),
      subCode: this.authService.getSubCode(),
      subName: this.authService.getSubName(),
      slotId: this.authService.getSlotId(),
      name: this.studentInfo.name,
      phone: this.studentInfo.phone,
      base64Image: result.canvas.toDataURL(),
    }
    this.service.addSuccessAuth(data).subscribe(result => {
    }, error => this.toastr.error(error.statusText, error.status));
  }
  authenticationFailed(result: any) {
    const data = {
      eNo: this.eNo,
      examCode: this.authService.getExamCode(),
      examName: this.authService.getExamName(),
      subCode: this.authService.getSubCode(),
      subName: this.authService.getSubName(),
      slotId: this.authService.getSlotId(),
      name: this.studentInfo.name,
      phone: this.studentInfo.phone,
      base64Image: result.canvas.toDataURL()
    }
    this.service.addFailedAuth(data).subscribe(result => {
    }, error => this.toastr.error(error.statusText, error.status));
  }

  logout() {
    let reasonDetails = {
      eNo: this.authService.getENo(),
      examCode: this.authService.getExamCode(),
      subCode: this.authService.getSubCode(),
      slotId: this.authService.getSlotId(),
      status: "logout",
      reason: "Normal logout"
    }
    this.service.getAuthenticateUser(reasonDetails).subscribe(results => {
      if (this.isSmartlockEnable === "Yes") {
        this.authService.logout();
        window.logout();
        window.location.replace(`${serverUrl}/home.html`);
      } else {
        this.authService.logout();
        window.location.replace(`${serverUrl}/home.html`);
      }
      //// this.getCsrfToken();
    }, error => {
      console.log(error);
      this.toastr.error('Network problem.');
    });
  }
}
