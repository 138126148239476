import { PersonalService } from './../services/personal.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class StartExamGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private stepService: PersonalService,
    private router: Router
  ) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise(resolve => {
        // if( this.stepService.isCompletedAllSteps() ) {
              if(this.authService.isExistToken()) {
                let token  = {
                  token: this.authService.getToken(),
                  examCode: this.authService.getExamCode(),
                  subCode: this.authService.getSubCode(),
                  slotId: this.authService.getSlotId(),
                  eNo: this.authService.getENo()
                }            
                this.authService.authenticateExamStartTime(token).subscribe( (result: any ) => {
                        if (result) {
                          resolve(true);
                        } else {
                            this.toastr.error('Exam already given or time not started.');
                            resolve(false);                            
                        }
                    }, error => {
                        this.toastr.error('User not validated. Sign in again', error.status);
                        resolve(false);                        
                    }
                );
              } else {                
                this.toastr.error('Exam time over or not started.');
                  resolve(false);
              }
        // } else {
          
        //       resolve(false);
        //       switch(true) {
        //           // case !this.stepService.getIsCompleteDontDoThis(): {
        //           //       this.toastr.error(`Read don't do this page first.`);
        //           //       this.router.navigate(['/student/entryPage']);
        //           //       break;
        //           // }
        //           case !this.stepService.getIsCompleteInstruction(): {
        //                 this.toastr.error(`Read all instructions first.`);
        //                 this.router.navigate(['/student/instructions']);
        //                 break;
        //           }
        //           case !this.stepService.getIsCompleteVerifyDetail(): {
        //                 this.toastr.error(`Verify your detail first.`);
        //                 this.router.navigate(['/student/instructions']);
        //                 break;
        //           }
        //           // case !this.stepService.getIsCompleteUsbList(): {
        //           //       this.toastr.error(`Check USB list first.`);
        //           //       this.router.navigate(['/student/usbDevices']);
        //           //       break;
        //           // }
        //           // case !this.stepService.getIsCompleteAudioCheck(): {
        //           //       this.toastr.error(`Read don't do this page first.`);
        //           //       this.router.navigate(['/student/audioCheck']);
        //           //       break;
        //           // }
        //           // case !this.stepService.getIsCompleteCalibration(): {
        //           //       this.toastr.error(`Read don't do this page first.`);
        //           //       this.router.navigate(['/student/calibrate']);
        //           //       break;
        //           // }
        //       }
        // }
        

      });
  }
  
}
