import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { serverUrl } from 'src/environments/environment';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  socketData1: any;
  color: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public socketData: any,
    private authService: AuthService,
    public dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {
    if (this.socketData.socketData === "success") {
      this.socketData1 = "Handshaking with Smartlock application successfull. You are now in a secured environment and can proceed for the exam.";
      this.color = "green";
    } else if (this.socketData.socketData === "failed") {
      this.socketData1 = "Socket Connection Failed";
      setTimeout(() => {
        this.authService.logout();
        window.location.replace(`${serverUrl}/home.html`);
      }, 10000)
    } else if (this.socketData.socketData === "close" || this.socketData.socketData === "error") {
      this.socketData1 = "Handshaking with Smartlock application failed. You cannot take the examination without smartlock application. Please install if not installed, and run the application.";
      this.color = "red";
      setTimeout(() => {
        this.authService.logout();
        window.location.replace(`${serverUrl}/home.html`);
      }, 10000)
    } else {
      this.socketData1 = "Handshaking with Smartlock application failed. You cannot take the examination without smartlock application. Please install the application if not installed, and run the application."
      this.color = "red";
      setTimeout(() => {
        this.authService.logout();
        window.location.replace(`${serverUrl}/home.html`);
      }, 10000)
    }
    setTimeout(() => {
      this.dialogRef.close('Pizza!');
    }, 10000)

    //=========================use for Auto Redirect To instruction Page====================
    // if (this.socketData.socketData === "failed") {
    //   this.socketData1 = "Socket Connection Failed";
    //   setTimeout(() => {
    //     this.authService.logout();
    //     window.location.replace(`${serverUrl}/home.html`);
    //   }, 3000)
    // } else if (this.socketData.socketData === "close" || this.socketData.socketData === "error") {
    //   this.socketData1 = "Socket Connection Close";
    //   setTimeout(() => {
    //     this.authService.logout();
    //     window.location.replace(`${serverUrl}/home.html`);
    //   }, 3000)
    // } else {
    //   this.socketData1 = "SmartLock App is not Installed Please Install 1st"
    //   setTimeout(() => {
    //     this.authService.logout();
    //     window.location.replace(`${serverUrl}/home.html`);
    //   }, 3000)
    // }
  }
}

