import { ExamLayoutComponent } from './layout/exam-layout/exam-layout.component';
import { StartExamGuard } from './../auth/start-exam.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InstructionsComponent } from './instructions/instructions.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { AuthGuard } from '../auth/auth.guard';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
    {
        path: 'student',
        component: ExamLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: "", redirectTo: 'home', pathMatch: 'full' },
            { path: "home", canActivate: [AuthGuard], component: HomeComponent },
            { path: "instructions", canActivate: [AuthGuard], component: InstructionsComponent },
            { path: "questionAnswer", canActivate: [AuthGuard, StartExamGuard], component: QuestionAnswerComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ExamRoutingModule { }
