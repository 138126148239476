<div class="container-fluid midcontents">
	<div class="container">
		<div class="row logobar">
			<div class="col-md-8">
				<img src="assets/new-template/images/logo.png" />
			</div>
			<div class="col-md-4">
				<h4><strong>Welcome, {{ studentInfo.name }}</strong></h4>
				<h4><strong>Enrollment No. - {{ studentInfo.eNo }}</strong></h4>
				<button class="btn" (click)="logout()"><a>Logout</a></button>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="accordion" id="accordionExample">
			<div class="steps">
				<progress id="progress" value="0" max="100"></progress>
				<div class="step-item">
					<button class="step-button text-center" type="button" data-toggle="collapse"
						data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
						1
					</button>
					<div class="step-title">Instructions</div>
				</div>
				<div class="step-item">
					<button (click)="verifyStudentStart()" class="step-button text-center collapsed" type="button"
						data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
						aria-controls="collapseTwo" style="float: right">
						2
					</button>
					<div class="step-title">Proceed</div>
				</div>
			</div>

			<div class="card">
				<div id="headingOne"></div>

				<div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
					data-parent="#accordionExample">
					<div class="card-body">
						<div class="instructionbox">
							<div class="row">
								<div class="col-md-6 borderri">
									<div class="instructionsteps">
										<ul>
											<li *ngFor="let i of result">{{ i.instruction }}</li>
										</ul>
									</div>
								</div>
								<div class="col-md-6">
									<div class="instructionsimage">
										<strong>
											Please read all instructions carefully, <br />
											then click on proceed button to verify your
											Profile .</strong>
									</div>
								</div>
							</div>

							<!-- <button class="btn"><a >I agree <i cl ass="fas fa-long-arrow-alt-right"></i></a></button>						 -->
						</div>
					</div>
				</div>
			</div>
			<div class="card">
				<div id="headingTwo"></div>
				<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
					<div class="card-body">
						<div class="detailsbox verification">
							<div class="row">
								<div class="col-md-6">
									<div class="odd">
										<p>Enrollment Number: {{ studentInfo.eNo }}</p>
									</div>
									<div class="even">
										<p>Name: {{ studentInfo.name }}</p>
									</div>
									<div class="odd">
										<p>Cat 1: {{ studentInfo.cat1 }}</p>
									</div>
									<div class="even">
										<p>Cat 2: {{ studentInfo.cat2 }}</p>
									</div>
									<div class="odd">
										<p>Mobile no.: {{ studentInfo.phone }}</p>
									</div>
								</div>
								<div class="col-md-6">
									<div class="registeredimg" *ngIf="getIsFaceAuthenticationEnableOrNot">
										<img alt="" src="{{ studentInfo.imageUrl }}" />
										<h5>Registered photograph</h5>
									</div>
									<!--  *ngIf="!faceAuthEnableOrNot" -->
									<div class="procedimg">
										<div id="videoTag">
											<video id="testStudentPicture" width="100%" height="100%" playsinline muted
												autoplay></video>
										</div>
										<h5>{{ matchPictureMsg }}</h5>
									</div>
									<div class="detailchat">
										<div class="odd">
											<p>
												Exam name (code): {{ examInfo.examName }} ({{
												examInfo.examCode
												}})
											</p>
										</div>
										<div class="even">
											<p>
												Subject name (code): {{ examInfo.subName }} ({{
												examInfo.subCode
												}})
											</p>
										</div>
									</div>
								</div>
							</div>
							<button class="btn" (click)="proceed()" *ngIf="!faceAuthEnableOrNot" [disabled]="!pictureIsMatch"
								onclick="window.openFullscreen();">
								<a>Start Exam</a>
							</button>
							<button class="btn" (click)="proceed()" *ngIf="faceAuthEnableOrNot"
								onclick="window.openFullscreen();">
								<a>Start Exam</a>
							</button>
							<!-- commented for oncampus  -->
							<!-- <button class="btn" (click)="proceed()" onclick="window.openFullscreen();">
								<a>Start Exam</a>
							</button> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>