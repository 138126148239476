<div class="container-fluid topbar">
	<div class="container">
		<div class="row">
			<div class="col-md-2">
				<!-- Latest News -->
			</div>
			<div class="col-md-6">

			</div>
			<div class="col-md-4 zoombox">
				<ul>
					<li><img src="assets/new-template/images/plus.png" /></li>
					<li><img src="assets/new-template/images/100_1.png" /></li>
					<li><img src="assets/new-template/images/minus.png" /></li>
				</ul>
				<ul>
					<li><img src="assets/new-template/images/100.png" class="colorbli" /></li>
					<li><img src="assets/new-template/images/black.png" class="colorbli" /></li>

				</ul>
			</div>
		</div>
	</div>
</div>