import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exam-header-link',
  templateUrl: './exam-header-link.component.html',
  styleUrls: ['./exam-header-link.component.css']
})
export class ExamHeaderLinkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
